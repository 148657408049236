import React from "react";
import "./home.css";
import image1 from "../../assets/mettabrowser-white.png";
import image2 from "../../assets/MettaPay.png";
import image3 from "../../assets/metapas.png";
import { FaAngleRight } from "react-icons/fa";
const CardTwo = () => {
  const data = [
    {
      id: 1,
      image: image1,
      description:
        "Metta Browser is a web5 browser building together an extra decentralized web that puts you in control of your data, identity and privacy. Web5 is a new evolution of the Web that enables decentralized apps and protocols. Mine METTA in-browser. 100% decentralized.",
      link: "https://www.mettabrowser.com",
    },
    {
      id: 2,
      image: image2,

      description:
        "With MettaPay Concierge, buying and selling cryptocurrency is simple and straightforward. Plus, our partnership with Transak allows you to get the best rates in the market. MettaPay Concierge offers a hassle free way for individuals from all over the world.",
      link: "https://www.mettabrowser.com",
    },
    {
      id: 3,
      image: image3,
      description:
        "Introducing MettaPass, the revolutionary Web5 social network that allows you to take your online interactions to the next level. With MettaPass, you can connect with old friends and make new ones from around the globe like never before!",
      link: "https://www.mettapays.com",
    },
  ];
  return (
    <>
      <div className="contianer_card">
        {data.map((item) => {
          return (
            <div className="cardTwoContainer">
              <div className="imagCardTwoContainer">
                <img src={item.image} alt="" />
              </div>
              <p className="description">{item.description}</p>
              <a href={item.link}>
                <button className="cardTwoBtnBTN flex justify-center items-center flex-nowrap">
                  Launch DAPP
                  <span className="span_2">
                    <FaAngleRight
                      style={{
                        marginLeft: "1rem",
                        height: "1.5rem",
                        width: "1rem",
                      }}
                    />
                  </span>
                </button>
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CardTwo;
