import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import Navbar from "../header/NavBar";
import { handleWalletConnect } from "./functions";
import CONFIG from "./abi/config.json";
import abi from "./abi/abi.json";
import logo from "../../assets/logo.png";
import { useEffect, useContext, useRef, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import "./ico.css";
import { disconnectWallet } from "./functions";
import { addToken } from "./functions";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import LoadingComponent from "../nftStaking/LoadingComponent";
import LoadingScreen from "../staking/LoadingScreen";

import CROWDSALE_ABI from "./abi/abi.json";
const crowdsaleAddress = CONFIG.ICO_CONTRACT_ADDRESS;

const Ico = () => {
  const [dataLoading, setDataLoading] = useState(false);

  const {
    account,
    blockChainData,
    error,
    errMsg,
    mintingData,
    web3Provider,
    bnbBalance,
    mettaTokenBalance,
    updateError,
    updateErrMsg,
    updateAccount,
    updateStakedBalance,
    updateRewardBalance,
    updateTokenBalance,
    updateWeb3Provider,
    fetchAccountData,
    fetchMintingDataRequest,
    fetchMintingDataSuccess,
    fetchMintingDataFailed,
    fetchMintingData,
    updateBNBBalance,
  } = useContext(GlobalContext);
  const { tokenBalance } = blockChainData;
  console.log("tokenBalance", mettaTokenBalance);
  console.log("bnbBalance", bnbBalance);
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        updateAccount(accounts[0]);
        fetchAccountData(web3Provider);
      });
      window.ethereum.on("chainChanged", (chainId) => {
        window.location.reload();
      });
    }
  }, [account]);
  const ethPrice = useRef(null);

  const buyToken = async (e) => {
    try {
      e.preventDefault();
      if (!window.ethereum) {
        updateError(true);
        updateErrMsg("Please install metamask");
        return;
      }
      if (!account) {
        updateError(true);
        updateErrMsg("Please connect to metamask");
        return;
      }
      setDataLoading(true);
      const web3modal = new Web3Modal();
      const instance = await web3modal.connect();
      const provider = new ethers.providers.Web3Provider(instance);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        crowdsaleAddress,
        CROWDSALE_ABI,
        signer
      );
      const price = ethers.utils.parseEther(ethPrice.current.value);
      const balance = ethers.utils.formatEther(
        await provider.getBalance(signer.getAddress())
      );

      if (balance < ethPrice.current.value) {
        setDataLoading(false);
        updateError(true);
        updateErrMsg("Insufficient balance");
        return;
      }

      const transaction = await contract.buyTokens(account, {
        value: price.toString(),
      });
      await transaction.wait();

      setDataLoading(false);
      updateError(true);
      updateErrMsg("Transaction successful");
    } catch (e) {
      setDataLoading(false);
    }
  };
  return (
    <>
      <LoadingScreen dataLoading={dataLoading} />
      <Header />
      <Navbar />
      <div className="w-full flex items-center flex-col">
        <div className="max-w-[500px] p-2">
          <img src={logo} alt="logo" />
        </div>
        <div className="mt-[2rem] sm:mt-0">
          {account ? (
            <div className="flex items-center flex-col">
              <a
                href={`${CONFIG.BLOCKCHAIN_EXPLORER}address/${account}`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-gradient"
              >
                {account.slice(0, 5) + "..." + account.slice(38, 42)}
              </a>
              <button
                className="text-xs text-right hover:text-[#f98747] mt-2 text-white"
                onClick={() =>
                  disconnectWallet(
                    updateAccount,
                    updateStakedBalance,
                    updateRewardBalance,
                    updateTokenBalance,
                    updateWeb3Provider
                  )
                }
              >
                Disconnect
              </button>
            </div>
          ) : (
            <button
              className="btn-gradient"
              onClick={() =>
                handleWalletConnect(
                  updateWeb3Provider,
                  updateAccount,
                  updateError,
                  updateErrMsg,
                  fetchAccountData
                )
              }
            >
              Connect Wallet
            </button>
          )}
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="my-11 p-7 w-[95%] lg:w-[1120px] flex items-center flex-col md:flex-row justify-between border border-white border-opacity-20 rounded-3xl shadow-xl ">
          <div className="md:pl-8 text-center md:text-left md:mr-2">
            <h1 className="bg-gradient-to-r from-[#f98747] via-[#cf2442] text-base sm:text-xl font-bold uppercase">
              Initial Coin Offering
            </h1>
            <h1 className="text-2xl sm:text-4xl font-bold uppercase text-white">
              METTA PROTOCOL
            </h1>
            <button
              className="mt-5 px-6 py-2 btn-gradient"
              onClick={() => addToken()}
            >
              Add Token to your MetaMask
            </button>
            <div className="mt-10 text-left">
              <h3 className=" uppercase text-sm font-semibold mb-2 text-[#f98747]">
                Instructions:
              </h3>
              <ul className="text-sm list-outside list-disc text-white">
                <li className="ml-4">Minimum purchase allowed: 0.01 BNB</li>
                <li className="ml-4">
                  Purchase amount should be multiple of minimum purchase
                </li>
              </ul>
            </div>
          </div>
          <div className="my-10 border p-10 rounded-xl border-white border-opacity-30 flex flex-col justify-center items-start">
            <div className="pl-7 lg:pl-8 flex flex-col justify-start items-start w-full">
            {account && (
              <>
                <p className="text-sm text-white">BNB Balance: {bnbBalance}</p>
                <p className="text-sm text-white">
                  Your Metta Balance: {mettaTokenBalance}{" "}
                </p>
              </>
            )}
           </div>
            <div className=" flex flex-col justify-center items-center w-full">
            <form onSubmit={buyToken}>
              <div className="my-3">
                <label className="text-base font-bold text-[#f98747]">
                  Amount BNB
                </label>
                <input
                  ref={ethPrice}
                  type="text"
                  className="w-full bg-transparent border-2 border-[color:var(--border-color)] text-md text-white focus:outline-none px-3 py-2"
                  required
                />
              </div>
              <div className="my-3">
                <label className="text-base font-bold text-[#f98747]">
                  Rate
                </label>
                <input
                  className="w-full bg-transparent border-2 border-[color:var(--border-color)] text-md text-white focus:outline-none px-3 py-2"
                  type="text"
                  value="$0.01"
                  disabled
                />
              </div>

              <div className="mt-10">
                <button
                  disabled={dataLoading}
                  className="w-full py-2 px-6 uppercase btn-gradient font-bold"
                >
                  {dataLoading ? "Busy" : "Buy"}
                </button>
              </div>
            </form>
             </div>
          </div>
        </div>
        <div className="before:fixed before:top-0 before:left-0 before:w-full page__bg -z-30"></div>
      </div>
      <Footer />
    </>
  );
};

export default Ico;
