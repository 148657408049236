import { ethers } from "ethers";
import { createContext, useReducer } from "react";
import CONFIG from "../abi/config";
import { AppReducer } from "./AppReducer";
import stakeABI from "./../abi/staking.json";
import tokenABI from "./../abi/token.json";
import mintingConfig from "../components/minting/config/config.json";
import mingtingABI from "../components/minting/config/abi.json";
import icoCONFIG from "../components/ico/abi/config.json";
import tokenICOAbi from "../components/ico/abi/token.json";

const initialState = {
  account: null,
  errMsg: "",
  error: false,
  web3Provider: null,
  blockChainData: {
    TokenBalance: null,
    StakeBalance: {
      plan0: null,
      plan1: null,
      plan2: null,
      plan3: null,
      plan4: null,
      plan5: null,
    },
    RewardBalance: {
      plan0: null,
      plan1: null,
      plan2: null,
      plan3: null,
      plan4: null,
      plan5: null,
    },
    TokenPrice: null,
    TotalRewards: null,
    TotalStaked: null,
    apy: {
      one_month_apy: null,
      three_month_apy: null,
      six_month_apy: null,
      nine_month_apy: null,
      one_year_apy: null,
      hundred_years_apy: null,
    },
  },
  mintingData: {
    loading: false,
    totalSupply: 0,
    cost: 0,
    maxSupply: 0,
    error: false,
    errorMsg: "",
  },
  bnbBalance: null,
  mettaTokenBalance: null,
  termsAndCondition: false,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const updateTokenBalance = (balance) => {
    dispatch({
      type: "UPDATE_TOKEN_BALANCE",
      payload: balance,
    });
  };

  const updateError = (error) => {
    dispatch({
      type: "UPDATE_ERROR",
      payload: error,
    });
  };

  const updateErrMsg = (errMsg) => {
    dispatch({
      type: "UPDATE_ERR_MSG",
      payload: errMsg,
    });
  };

  const updateStakedBalance = (balance) => {
    dispatch({
      type: "UPDATE_STAKED_BALANCE",
      payload: balance,
    });
  };

  const updateRewardBalance = (rewards) => {
    dispatch({
      type: "UPDATE_REWARDS_BALANCE",
      payload: rewards,
    });
  };

  const updateTokenPrice = (price) => {
    dispatch({
      type: "UPDATE_TOKEN_PRICE",
      payload: price,
    });
  };

  const updateTotalRewards = (rewards) => {
    dispatch({
      type: "UPDATE_TOTAL_REWARDS",
      payload: rewards,
    });
  };

  const updateTotalStaked = (totalStacked) => {
    dispatch({
      type: "UPDATE_TOTAL_STAKED",
      payload: totalStacked,
    });
  };
  const updateAccount = (account) => {
    dispatch({
      type: "UPDATE_ACCOUNT",
      payload: account,
    });
  };

  const updateWeb3Provider = (provider) => {
    dispatch({
      type: "UPDATE_PROVIDER",
      payload: provider,
    });
  };

  const updateApy = (apy) => {
    dispatch({
      type: "UPDATE_APY",
      payload: apy,
    });
  };
  const fetchMintingDataRequest = () => {
    return {
      type: "CHECK_DATA_REQUEST",
    };
  };

  const fetchMintingDataSuccess = (payload) => {
    return {
      type: "CHECK_DATA_SUCCESS",
      payload: payload,
    };
  };

  const fetchMintingDataFailed = (payload) => {
    return {
      type: "CHECK_DATA_FAILED",
      payload: payload,
    };
  };

  const updateBNBBalance = (balance) => {
    dispatch({
      type: "UPDATE_BNB_BALANCE",
      payload: balance,
    });
  };
  const updateMettaTokenBalance = (balance) => {
    dispatch({
      type: "UPDATE_METTA_TOKEN_BALANCE",
      payload: balance,
    });
  };
  const updateTermsAndCondition = (value) => {
    dispatch({
      type: "UPDATE_TERMS_AND_CONDITION",
      payload: value,
    });
  };
  const fetchAccountData = async (provider) => {
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const contract = new ethers.Contract(
      CONFIG.contractAddress,
      stakeABI,
      signer
    );
    const stakeBalance = await contract.stakeOf(address, 0);
    const stakeBalance1 = await contract.stakeOf(address, 1);
    const stakeBalance2 = await contract.stakeOf(address, 2);
    const stakeBalance3 = await contract.stakeOf(address, 3);
    const stakeBalance4 = await contract.stakeOf(address, 4);
    const stakeBalance5 = await contract.stakeOf(address, 5);
    const rewardBalance = await contract.getDailyRewards(0);
    const rewardBalance1 = await contract.getDailyRewards(1);
    const RewardBalance2 = await contract.getDailyRewards(2);
    const rewardBalance3 = await contract.getDailyRewards(3);
    const RewardBalance4 = await contract.getDailyRewards(4);
    const RewardBalance5 = await contract.getDailyRewards(5);
    const totalStake = await contract.totalStake();
    const totalReward = await contract.totalRewards();
    updateTotalRewards(
      ethers.utils.formatUnits(totalReward, CONFIG.tokenDecimals)
    );
    updateTotalStaked(
      ethers.utils.formatUnits(totalStake, CONFIG.tokenDecimals)
    );
    updateStakedBalance({
      plan0: ethers.utils.formatUnits(stakeBalance, CONFIG.tokenDecimals),
      plan1: ethers.utils.formatUnits(stakeBalance1, CONFIG.tokenDecimals),
      plan2: ethers.utils.formatUnits(stakeBalance2, CONFIG.tokenDecimals),
      plan3: ethers.utils.formatUnits(stakeBalance3, CONFIG.tokenDecimals),
      plan4: ethers.utils.formatUnits(stakeBalance4, CONFIG.tokenDecimals),
      plan5: ethers.utils.formatUnits(stakeBalance5, CONFIG.tokenDecimals),
    });
    updateRewardBalance({
      plan0: ethers.utils.formatUnits(rewardBalance, CONFIG.tokenDecimals),
      plan1: ethers.utils.formatUnits(rewardBalance1, CONFIG.tokenDecimals),
      plan2: ethers.utils.formatUnits(RewardBalance2, CONFIG.tokenDecimals),
      plan3: ethers.utils.formatUnits(rewardBalance3, CONFIG.tokenDecimals),
      plan4: ethers.utils.formatUnits(RewardBalance4, CONFIG.tokenDecimals),
      plan5: ethers.utils.formatUnits(RewardBalance5, CONFIG.tokenDecimals),
    });

    const tokenContract = new ethers.Contract(
      CONFIG.tokenAddress,
      tokenABI,
      signer
    );
    const nativeBalance = await provider.getBalance(address);
    updateBNBBalance(
      parseFloat(ethers.utils.formatEther(nativeBalance)).toFixed(4)
    );
    const balanceOf = await tokenContract.balanceOf(address);
    updateTokenBalance(
      ethers.utils.formatUnits(balanceOf, CONFIG.tokenDecimals)
    );
    const tokenContractsecond = new ethers.Contract(
      icoCONFIG.TOKEN_CONTRACT,
      tokenICOAbi,
      signer
    );
    const balanceOfPerson = await tokenContractsecond.balanceOf(address);
    updateMettaTokenBalance(
      ethers.utils.formatUnits(balanceOfPerson, icoCONFIG.TOKEN_DECIMAL)
    );
  };
  const getTokenBalance = async (signer, address) => {
    const tokenContract = new ethers.Contract(
      CONFIG.TOKEN_CONTRACT,
      tokenABI,
      signer
    );
    const balanceOf = await tokenContract.balanceOf(address);
    updateTokenBalance(
      ethers.utils.formatUnits(balanceOf, CONFIG.TOKEN_DECIMAL)
    );
  };

  const fetchMintingData = async (provider) => {
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const contract = new ethers.Contract(
      mintingConfig.CONTRACT_ADDRESS,
      mingtingABI,
      provider
    );
    return async (dispatch) => {
      dispatch(fetchMintingDataRequest());
      try {
        let totalSupply = await contract.totalSupply();
        let cost = await contract.getNFTPrice();
        let maxSupply = await contract._maxSupply();
        dispatch(
          fetchMintingDataSuccess({
            totalSupply,
            cost,
            maxSupply,
          })
        );
      } catch (error) {
        dispatch(fetchMintingDataFailed("Could not load data from contract."));
      }
    };
  };
  return (
    <GlobalContext.Provider
      value={{
        ...state,
        updateAccount,
        updateError,
        updateErrMsg,
        updateWeb3Provider,
        updateTokenBalance,
        updateStakedBalance,
        updateRewardBalance,
        updateTokenPrice,
        updateTotalRewards,
        updateTotalStaked,
        updateApy,
        fetchAccountData,
        fetchMintingDataRequest,
        fetchMintingDataSuccess,
        fetchMintingDataFailed,
        fetchMintingData,
        updateBNBBalance,
        updateTermsAndCondition,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
