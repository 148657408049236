import React from 'react'
import { ethers } from 'ethers'
import CONFIG from '../abi/config'
import nftStakingAbi from "../abi/nft_staking.json"
import { useEffect, useState } from 'react'

const useFetchStakedNfts = (provider, account, fetchStakedNFTs, setFetchStakedNFTs) => {
    const [stakedTokens, setStakedTokens] = useState([])
    const loadStakedNft = async () => {
       const signer = provider.getSigner();
       const stakingContract = new ethers.Contract(CONFIG.NFT_STAKING_CONTRACT, nftStakingAbi, signer);
       const staked_Tokens = await stakingContract.getUserStakedTokens(account)
       const tokenArray = await Promise.all(staked_Tokens.map(async (item) => {
            const tokenObject = await stakingContract.vault(item.toString())
            return tokenObject
         }))
         setStakedTokens(tokenArray)
         setFetchStakedNFTs(false)
         console.log(tokenArray)
    }
    useEffect(()=>{
        if (fetchStakedNFTs) {
            loadStakedNft()
        }
        else{
            console.log('no fetch')
        }
    }, [account, fetchStakedNFTs])

    return stakedTokens
}

export default useFetchStakedNfts