import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { useBlockChainData } from "./hooks/loadBlockchainData";
import Home from "./components/home";
import Dao from "./components/dao/Dao";
import Staking from "./components/staking/staking";
import BuyMetta from "./components/Swapping/BuyMetta";
import NFTstaking from "./components/nftStaking/NFTstaking";
import Payment from "./components/payment";
import AlertBox from "./components/alertBox/AlertBox";
import { GlobalContext } from "./context/GlobalContext";
import { useContext } from "react";
import Services from "./components/Services/Services";
import Minting from "./components/minting";
import LoadingScreen from "./components/staking/LoadingScreen";
import Ico from "./components/ico";
import TermsAndConditionCheck from "./Terms&ConditionCheck";
import axios from "axios";

function App() {
  const {
    account,
    error,
    errMsg,
    web3Provider,
    termsAndCondition,
    updateError,
    updateErrMsg,
    updateAccount,
    updateStakedBalance,
    updateRewardBalance,
    updateTokenBalance,
    updateWeb3Provider,
    fetchAccountData,
  } = useContext(GlobalContext);

  const [dataLoading, setDataLoading] = useState(false);
  useBlockChainData(setDataLoading);

  useEffect(() => {}, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dao" element={<Dao />} />
          <Route path="/Staking" element={<Staking />} />
          <Route path="/Swapping" element={<BuyMetta />} />
          <Route path="/services" element={<Services />} />
          {/* <Route path="NFT-staking" element={<NFTstaking />} /> */}
          <Route path="/payment" element={<Payment />} />
          <Route path="/minting" element={<Minting />} />
          <Route path="/ico" element={<Ico />} />
        </Routes>
      </Router>
      {error && <AlertBox />}
      <LoadingScreen dataLoading={dataLoading} />
      {termsAndCondition ? null : (
        <TermsAndConditionCheck termsAndCondition={termsAndCondition} />
      )}
    </>
  );
}

export default App;
