import React from "react";
import "./footer.css";
import logo from "../../assets/logo_hd.png";
import {
  BsTwitter,
  BsFacebook,
  BsInstagram,
  BsYoutube,
  BsDiscord,
  BsTelegram,
  BsReddit,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <footer className="footer">
        <div className="footer_container">
          <div className="list_one">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="social_media">
              <p>Join us on</p>
              <div className="social_links">
                <a href="https://twitter.com/mettatokens">
                  <BsTwitter
                    className="link"
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  />
                </a>
                <a href="">
                  <BsYoutube
                    className="link"
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  />
                </a>
                <a href="https://instagram.com/mettaprotocol">
                  <BsInstagram
                    className="link"
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  />
                </a>
                <a href="">
                  <BsDiscord
                    className="link"
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  />
                </a>
                <a href="https://www.facebook.com/profile.php?id=100086947025144&mibextid=LQQJ4d">
                  <BsFacebook
                    className="link"
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  />
                </a>
                <a href="https://t.me/mettaprotocoltoken">
                  <BsTelegram
                    className="link"
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  />
                </a>
                <a href="">
                  <BsReddit
                    className="link"
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  />
                </a>
              </div>
            </div>
            <div>
              <button className="btnFooter">Launch dapp</button>
            </div>
          </div>
          <div className="list_two">
            <h1>Citizen</h1>
            <ul>
              <li>METTA Token</li>
              <li onClick={() => (navigate("/staking"), window.scrollTo(0, 0))}>
                METTA Staking
              </li>
              <li>
                {" "}
                <a href="https://www.metta.network"> NFT Reciept Staking </a>
              </li>
              <li>
                {" "}
                <a href="https://www.mettabrowser.com"> Metta Browser </a>
              </li>
              <li>
                {" "}
                <a href="https://www.mettamotors.com"> Metta Motors </a>
              </li>
              <li>
                {" "}
                <a href="https://www.mettarooms.com"> Metta Hotels </a>
              </li>
              <li>
                {" "}
                <a href="https://www.mettagrill.com"> Metta Grill </a>
              </li>
              <li>
                {" "}
                <a href=""> Metta Supercar Event </a>
              </li>
            </ul>
          </div>
          <div className="list_three">
            <h1>Projects</h1>
            <ul>
              <li>
                <a href="https://www.mettapass.com">MettaPass</a>
              </li>
              <li
                onClick={() => (navigate("/Swapping"), window.scrollTo(0, 0))}
              >
                METTA Swap
              </li>
              <li>
                {" "}
                <a href="https://www.mettabrowser.com">Metta Browser</a>
              </li>
              <li>Metta Emails</li>
              <li>Metta Motors</li>
              <li>Metta Rooms</li>
            </ul>
          </div>
          <div className="list_four">
            <h1>Helpful Links</h1>
            <ul>
              <li>News On The Web3</li>
              <li>Our Citizens</li>
              <li>Related Press</li>
              <li>Contact</li>
              <li>Learn More</li>
              <li onClick={() => navigate("/services")}>Services</li>
            </ul>
          </div>
        </div>
        <div className="copy_rightContianer">
          <p className="copy_right">
            The content shared on this website is for information purpose only
            and, thus, should not be considered as financial advice.
            Trading/Investing is risky and you should never invest more than you
            can afford to lose. Cryptocurrencies are risky. Never invest more
            than you can afford to lose. You alone assume the sole
            responsibility of evaluating the merits and risks associated with
            the use of any information or other Content on the Site before
            making any decisions based on such information or other Content.
          </p>
        </div>
        <div className="bottom_container">
          <div className="bottom_footer">
            <div className="bottom_footer_inner">
              <div className="bottom_footer_links">
                <p>Terms Of Use</p>
              </div>
              <div className="dash"></div>
              <div className="bottom_footer_links">
                <p>Privacy Policy</p>
              </div>
              <div className="dash"></div>
              <div className="bottom_footer_links">
                <p>Sitemap</p>
              </div>
            </div>
            <div>
              <h3 className="copy_right_heading">
                Copyright 2022 Metta Protocol. All Rights Reserved.
              </h3>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
