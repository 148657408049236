import React from "react";
import "./nft.css";

import NFTMain from "./NFTMain";

import NavBar from "../header/NavBar";

const NFTstaking = () => {
  return (
    <>
      <div className="nftBG">
        <div className="flex justify-center items-center mt-20 ">
          <NFTMain />
        </div>
      </div>
    </>
  );
};

export default NFTstaking;
