import React from 'react'
import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import CONFIG from '../abi/config'
import ABI from '../abi/nft.json'

const useFetchNfts = (account, fetchNFTs, setFetchNfts) => {
 const [nft, setNft] = useState(null)
 const fetchWalletNfts = async (account) => {
    try {
        if(account) {
         const endpoint = process.env.REACT_APP_MORALIS_ENDPOINT
         const options = {method: 'GET', headers: {accept: 'application/json', 'X-API-Key': process.env.REACT_APP_MORALIS_APP_ID}};
         const res = await fetch(`${endpoint}${account}/nft?chain=bsc&format=decimal&token_addresses%5B0%5D=0x92D7B31fd396F4981eF174CeD6d7526E049a9C49&normalizeMetadata=true`, options)
         const resp = await res.json()
         console.log(resp.result)
        //  const provider = new ethers.providers.Web3Provider(window.ethereum)
        //  const contract = new ethers.Contract(CONFIG.NFT_CONTRACT_ADDRESS, ABI, provider)
        //  let baseUri = await contract.baseURI()
        //  if (baseUri.startsWith('ipfs://')) {
        //     baseUri = `https://ipfs.io/ipfs/${baseUri.split('ipfs://')[1]}`
        //  }
        //  const metadata = await Promise.all(resp.result.map(async item => {
        //     let res = await fetch(`${baseUri.trim()+parseInt(item.id.tokenId, 16)}.json`)
        //     let results = await  res.json()
        //     return {...results, tokenId: parseInt(item.id.tokenId, 16)}
        //  }))
        setNft(resp.result)
        setFetchNfts(false)
        // console.log(metadata)
        }
       
    }catch(err) {
        console.log(err)
    }
 }
 useEffect(()=>{
        if (fetchNFTs) {
            fetchWalletNfts(account)
        }
        else{
            console.log('no fetch')
        }
    }, [account, fetchNFTs])

    return nft
}

export default useFetchNfts