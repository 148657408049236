import React, { useState } from "react";
import { useBlockChainData } from "../../hooks/loadBlockchainData";
import LoadingScreen from "../staking/LoadingScreen";
import Header from "../header/header";
import NavBar from "../header/NavBar";
import "./home.css";
import hero from "../../assets/Hero-img.png";
import { FaAngleRight } from "react-icons/fa";
import CardOne from "./CardOne";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import { FiCopy as Copy } from "react-icons/fi";
import image1 from "../../assets/MettaPayLogo-46 copy.png";
import image2 from "../../assets/mettapassLogo-51 copy.png";
import image3 from "../../assets/mettabrowser-black copy.png";
import image4 from "../../assets/Metta Motors Logo-03 copy.png";
import image5 from "../../assets/mettafundLogo-56 copy.png";
import image6 from "../../assets/mettaseedLogo-54 copy.png";
import image7 from "../../assets/mettataxLogo-60 copy.png";
import CardTwo from "./CardTwo";
import CardThree from "./CardThree";
import CardFour from "./CardFour";
import Footer from "../footer/footer";
import AnchorLink from "react-anchor-link-smooth-scroll";
import CONFIG from "../../abi/config";

const Home = () => {
  const [copy, setCopy] = useState(false);
  const [copied, setCopied] = useState(false);

  const [dataLoading, setDataLoading] = useState(false);
  useBlockChainData(setDataLoading);

  const onCopy = () => {
    setCopied(true);
    toast.success("Copied To Clipboard !");
  };
  return (
    <>
      <div className="app">
        <div className="hero-section">
          <Header />
          <NavBar />
          <div className="main_container">
            <div className="main_container_left">
              <h1 className="text-white">
                Using blockchain to help the world solving complex financial
                crisis.
              </h1>
              <br />
              <p className="text-white">
                Metta Protocol is a blockchain technology company focusing on
                increasing the usibility of cryptocurrency and the blockchain
                technology in our everyday life. We develop Metta Token as a
                token that everyone can use to purchase anything in almost all
                industries. We partner with realestate developers, merchants,
                travel agencies, supermarkets to tokenized the world.
              </p>
              <div className="btn_container">
                <AnchorLink href="#METTA">
                  <button className="btn_ctn_1">
                    $METTA
                    <FaAngleRight
                      // style={{
                      //   marginLeft: "1rem",
                      //   height: "1.5rem",
                      //   width: "1rem",
                      // }}
                      className="ml-[0.7rem] h-[1.2rem] w-[0.7rem]  lg:ml-[1rem] lg:h-[1.5rem] lg:w-[1rem]"
                    />
                  </button>
                </AnchorLink>
                <button className="btn_ctn_2">
                  Launch DAPP
                  <FaAngleRight
                    // style={{
                    //   marginLeft: "1rem",
                    //   height: "1.5rem",
                    //   width: "1rem",
                    // }}
                    className="ml-[0.7rem] h-[1.2rem] w-[0.7rem]  lg:ml-[1rem] lg:h-[1.5rem] lg:w-[1rem]"
                  />
                </button>
              </div>
            </div>
            <div className="imgContainer">
              <img src={hero} alt="" />
            </div>
          </div>
        </div>
        <div className="papaDiv_scnd">
          <div className="container_second">
            <div className="container_second_left_inner">
              <div className="container_second_left">
                <h1>
                  The Highest Paying APY Protocol
                  <span style={{ color: "#cf2442" }}> Over 15%</span> Fixed
                  Staking APY
                </h1>
                <div className="second_btn_container">
                  <button className="scnd_btn">
                    <span className="middleSecond">
                      <FaAngleRight />
                    </span>
                    whitepaper
                  </button>
                  <button className="scnd_btn_2">
                    <span className="middleSecond_1">
                      <FaAngleRight />
                    </span>
                    audit
                  </button>
                </div>
              </div>
              <div className="container_second_right">
                <h1>
                  About The <br /> <span className="middle">Metta</span>{" "}
                  Protocol
                </h1>
                <br />
                <p>
                  We develop Metta Token as a token that everyone can use to
                  purchase anything in almost all industries. We partner with
                  realestate developers, merchants, travel agencies,
                  supermarkets to tokenized the world.
                </p>
                <br />
                <p>
                  Metta provides a decentralized financial asset which rewards
                  its holders automatically in dozens of ways. From our buying
                  and selling fees, we automatically re-distribute tokens to all
                  holders. It’s like recycling the economy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container_third">
            <div>
              <h1>Built to realize the promises of web3.</h1>
              <p className="span_1 flex justify-center items-center">
                We develop Metta Protocol as a token that everyone can use to
                purchase anything in almost all industries. We partner with
                realestate developers, merchants, travel agencies, supermarkets
                to tokenized the world.
              </p>
            </div>
            <div>
              <CardOne />
            </div>
          </div>
        </div>
        <div className="papaDiv_Third">
          <div className="container_fourth" id="METTA">
            <div>
              <h1>Metta Contract Address</h1>
            </div>
            <div className="mt-4 flex">
              <CopyToClipboard
                style={{ cursor: "pointer" }}
                onCopy={onCopy}
                text="0xf5135EA29aD9705E9FFFECdBe27BFBd30a60C323"
              >
                <div className="span_3 flex">
                  <span>0xf5135EA29aD9705E9FFFECdBe27BFBd30a60C323</span>
                  <Copy
                    size={30}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2rem",
                      border: "1px solid #cf2442",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  />
                  <Toaster />
                </div>
              </CopyToClipboard>
            </div>
            <div style={{ marginTop: "4rem" }}>
              <button className="scnd_btn__1">
                <span className="middleSecond">
                  <FaAngleRight />
                </span>
                click here to buy metta
              </button>
            </div>
            <div className="container_fourth_inner">
              <div className="container_fourth_inner_left">
                <h1>
                  Get METTA tokens with Metamask, TrustWallet, Formatic or any
                  WalletConnect compatible wallets in seconds!
                </h1>
                <div className="inner_div_left">
                  <ul className="">
                    <li>
                      Download <a href="https://metamask.io/">MetaMask</a>{" "}
                      mobile app or the Google Chrome Extension.
                      <br />
                      You can also download and use the{" "}
                      <a href="https://trustwallet.com/">TrustWallet</a> mobile
                      app.
                    </li>
                    <li>
                      Then you will have to add the Binance Smart Chain to your
                      network-list for the Metamask. (
                      <a href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain">
                        Click here for a step-by-step tutorial
                      </a>
                      )
                    </li>
                    <li>
                      Buy or send BNB Smart Chain (BEP token starts with 0x…) to
                      your wallet.
                    </li>
                    <li>
                      Go to the browser inside of the wallet by tapping on the 3
                      little bars on the left or right top of the wallet. Search
                      or type in www.mettatoken.org; connect your wallet and
                      approve it. The Metta Token Address to your wallet:
                      <br />
                      <span
                        style={{ color: "#cf2442", wordBreak: "break-all" }}
                      >
                        {CONFIG.tokenAddress}
                      </span>
                    </li>
                    <li>
                      After connecting your wallet, then simply click on “Add
                      Token To Your Wallet”, add the amount you want to
                      purchase, then click BUY and then “
                      <span style={{ color: "#cf2442" }}>Approve</span>” it,
                      then wait until it says: “
                      <span style={{ color: "#cf2442" }}>Purchase Done</span>”!
                    </li>
                    <li>All Done!</li>
                  </ul>
                </div>
              </div>
              <div className="container_fourth_inner_right">
                <h1>
                  Why Should You <span className="middle">Buy</span> Metta
                  Token?
                </h1>
                <div>
                  <br />
                  <p>
                    <span
                      style={{
                        color: "#cf2442",
                      }}
                    >
                      Metta Token
                    </span>{" "}
                    was developed to address the issues that caused previous
                    projects to fail. We’ve studied failed projects and
                    identified the reasons for their failure. With this
                    knowledge, we’ve created a safe and innovative project that
                    everyone can trust. Invite your family and friends to be
                    part of the Metta Token community and help us build a better
                    future!
                  </p>
                  <p>
                    This innovative protocol was developed to provide a
                    foundation for useful applications in the crypto space. With
                    Metta Protocol, you can be sure you're investing in a
                    project with a solid foundation and everlasting value.
                  </p>
                  <p>
                    The cryptocurrency industry is full of hyped up projects
                    that lack any real foundation or utility. Metta Protocol was
                    developed to be different and to provide a much needed
                    foundation for useful applications. The protocol is the
                    foundation of all our utilities and provides everlasting
                    value. With Metta Protocol, you can be sure you're investing
                    in a project with real substance.
                  </p>
                  <p>
                    Metta Protocol is 99% more effective than other
                    cryptocurrencies, and its utilities are truly
                    groundbreaking. With Metta Protocol, you can transact with
                    anyone in the world instantly and securely. Plus, our
                    protocol is designed to scale, so it can handle an infinite
                    number of transactions per second.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="papaDiv_Third">
          <div className="container_fifth">
            <div className="container_fifth_box">
              <div className="inner_fifth">
                <div className="inner_fifth_left">
                  <img src={image1} alt="" />
                </div>
                <div className="inner_fifth_right">
                  <img src={image2} alt="" />
                </div>
              </div>
              <div className="inner_fifth">
                <div className="inner_fifth_left">
                  <img src={image3} alt="" />
                </div>
                <div className="inner_fifth_right">
                  <img src={image4} alt="" />
                </div>
              </div>
              <div className="inner_fifth">
                <div className="inner_fifth_left">
                  <img src={image5} alt="" />
                </div>
                <div className="inner_fifth_right">
                  <img src={image6} alt="" />
                </div>
              </div>
              <div className="inner_fifth">
                <div className="inner_fifth_left">
                  <img src={image7} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="papaDiv_Third">
          <div className="container_sixth">
            <div className="container_sixth_inner">
              <h1>How The Metta Protocol Works</h1>
              <div className="sixthHR" />
              <div className="sixth_para">
                <p>
                  DeFi Protocol to boost METTA tokens popularity and BNB
                  rewards. We develop Metta Token as a token that everyone can
                  use to purchase anything in almost all industries. We partner
                  with realestate developers, merchants, travel agencies,
                  supermarkets to tokenized the world.
                </p>
              </div>
              <div>
                <CardTwo />
              </div>
            </div>
          </div>
        </div>
        <div className="papaDiv_Third">
          <div className="container_seventh">
            <div className="container_seventh_inner">
              <h1>Explore The Metta Protocol Ecosystem</h1>
              <br />
              <p>
                We develop Metta Token as a token that everyone can use to
                purchase anything in almost all industries. We partner with
                realestate developers, merchants, travel agencies, supermarkets
                to tokenized the world.
              </p>
            </div>
            <div>
              <CardThree />
            </div>
          </div>
        </div>
        <div className="">
          <div className="container_eighth">
            <div className="container_eighth_inner">
              <h1>Articles & Updates</h1>
              <CardFour />
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

var Tawk_API = Tawk_API || {},
  Tawk_LoadStart = new Date();
(function () {
  var s1 = document.createElement("script"),
    s0 = document.getElementsByTagName("script")[0];
  s1.async = true;
  s1.src = "https://embed.tawk.to/638f9e85b0d6371309d2f446/1gjkg6fla";
  s1.charset = "UTF-8";
  s1.setAttribute("crossorigin", "*");
  s0.parentNode.insertBefore(s1, s0);
})();
export default Home;
