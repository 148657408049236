import React from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import NavBar from "../header/NavBar";
import "./dao.css";

const Dao = () => {
  return (
    <div>
      <Header />
      <NavBar />
      <div className="papa_div">
        <div className="dao_container">
          <h1>What is Metta Protocol DAO?</h1>
          <div className="box_container">
            <div className="dao_left">
              <p>
                Welcome to the world of Metta Protocol DAO - a new decentralized
                autonomous organization that is reshaping how smart contracts
                are built and used. With its groundbreaking technology,
                developers can now create, trade, and manage smart contracts
                from within one blockchain-agnostic ecosystem. Metta Protocol
                DAO lets developers connect to networks everywhere with just a
                few lines of code.
              </p>
              <br />
              <p>
                Developers who join the Metta Protocol DAO will be part of a
                vibrant network of other driven engineers who are leaders in
                their respective fields. Together, they will share best
                practices and collaborate on pioneering new solutions on top of
                existing infrastructure. And they can do this while protecting
                the integrity and reliability of the entire system with
                self-governance enforced by consensus protocols.
              </p>
              <br />
              <p>
                This is an incredible opportunity for any web developer looking
                to stay ahead in a rapidly-evolving digital landscape. With
                Metta Protocol’s composable smart contracts, you can quickly
                develop robust applications with unparalleled security – all
                while ensuring full transparency throughout your entire project
                lifecycle. So don’t miss out– become a member of the Metta
                Protocol DAO today and experience what’s possible in today’s
                modern development world!
              </p>
            </div>
            <div className="dao_right">
              <p>
                Mission Our mission is to onboard 10 million, driven web
                developers to Web3, by making it familiar and easy to build
                robust blockchain apps without writing a single line of smart
                contract code. And, we're doing this with the power of community
                and Web3 culture.
              </p>
              <br />
              <p>
                At Metta Protocol, we understand that great things come from
                community. By having an open ecosystem and dedicated team of
                developers who are excited about the potential for Web3, we can
                onboard millions more into this expansive digital space. What’s
                more, our versatile suite of products gives users flexibility
                and customizability depending on their projects. From product
                development to startups – whatever it is you want to create on
                Web3, Metta Protocol has something for you!
              </p>
              <br />
              <p>
                Join Metta Protocol today and be at the forefront of
                transformation happening in the world of distributed ledger
                technology. Our effortless platform provides maximum
                monetization opportunities so that your projects can reach their
                full potential quickly and securely – all without writing a
                single line of code yourself! Elevate your web development game
                with us now!
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dao;
