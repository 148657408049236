import React, { useState } from "react";
import "./header.css";
import { FaBars } from "react-icons/fa";
import logo from "../../assets/MettaProtocol Logo.png";
import { FaAngleRight } from "react-icons/fa";
import { GoTriangleDown } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { useContext } from "react";
import { useEffect } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CONFIG from "../../abi/config";

const MobileHeader = () => {
  const {
    account,
    web3Provider,
    updateError,
    updateErrMsg,
    updateAccount,
    updateStakedBalance,
    updateRewardBalance,
    updateTokenBalance,
    updateWeb3Provider,
    fetchAccountData,
  } = useContext(GlobalContext);
  const providerOptions = {
    network: "mainnet",
    catchProvider: false,
    walletconnect: {
      package: WalletConnectProvider,
      rpc: {
        97: process.env.REACT_APP_BSC_RPC,
      },
    },
  };
  const handleWalletConnect = async () => {
    const web3modal = new Web3Modal({
      providerOptions,
    });
    const instance = await web3modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);
    updateWeb3Provider(provider);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    updateAccount(address);
    const network = await provider.getNetwork();
    console.log(network);
    if (network.chainId !== CONFIG.chainId) {
      updateError(true);
      updateErrMsg(
        `Contract is not deployed on current network. please choose ${CONFIG.networkName}`
      );
    } else {
      updateError(false);
      updateErrMsg("");
      fetchAccountData(provider);
      // loadAccountData(signer, address)
    }
  };

  const disconnectWallet = () => {
    updateAccount(null);
    updateStakedBalance({
      plan0: null,
      plan1: null,
      plan2: null,
      plan3: null,
      plan4: null,
    });
    updateRewardBalance({
      plan0: null,
      plan1: null,
      plan2: null,
      plan3: null,
      plan4: null,
    });
    updateTokenBalance(null);
    updateWeb3Provider(null);
  };
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [menuClass, setMenuClass] = useState("mene_class");
  const [launchDapp, setLaunchDapp] = useState(false);
  const [ecosystem, setEcosystem] = useState(false);
  const handleClick = () => {
    setMenu(!menu);
    if (menu) {
      setMenuClass("menu_class animate__animated animate__fadeOutLeft");
    } else {
      setMenuClass("menu_class animate__animated animate__fadeInLeft");
    }
  };
  return (
    <>
      <div className="mobile_header">
        <div onClick={handleClick}>
          <FaBars style={{ color: "#817D80" }} />
        </div>
        {menu ? (
          <div className={menuClass}>
            <div>
              <div className="cross">
                <RxCross2 onClick={handleClick} />
              </div>
              <ul>
                <li>
                  <button
                    className="mobileBTN"
                    onClick={() => setLaunchDapp(!launchDapp)}
                  >
                    LAUNCH DAPP
                    <FaAngleRight
                      style={{
                        marginLeft: "4px",
                        width: "1.2rem",
                        height: "1.2rem",
                      }}
                    />
                    <GoTriangleDown style={{ marginLeft: "4px" }} />
                  </button>
                  {launchDapp ? (
                    <div className="innerLi_mobile">
                      <li
                        className="innerli_mobile"
                        onClick={() => navigate("/Swapping")}
                      >
                        <span style={{ paddingLeft: "10px" }}>METTASWAP </span>
                      </li>
                      <li
                        className="innerli_mobile"
                        onClick={() => navigate("/staking")}
                      >
                        <span style={{ paddingLeft: "10px" }}>METTASTAKE</span>
                      </li>
                      <li
                        className="innerli_mobile"
                        onClick={() => navigate("/minting")}
                      >
                        <span style={{ paddingLeft: "10px" }}>NFT Minting</span>
                      </li>
                      <li
                        className="innerli_mobile"
                        onClick={() => navigate("/ico")}
                      >
                        <span style={{ paddingLeft: "10px" }}>METTA ICO</span>
                      </li>
                    </div>
                  ) : null}
                </li>
                <li>
                  <span
                    onClick={() => setEcosystem(!ecosystem)}
                    className="flex"
                  >
                    Ecosystem
                    <GoTriangleDown
                      style={{ marginLeft: "7px", marginTop: "4px" }}
                    />
                  </span>
                  {ecosystem ? (
                    <div className="innerLi2_mobile">
                      <li className="innerli2">
                        <a
                          style={{ paddingLeft: "10px" }}
                          href="https://www.mettamotors.com"
                          target="_blank"
                        >
                          Metta Motors&trade;
                        </a>
                      </li>
                      <li className="innerli2">
                        <a
                          style={{ paddingLeft: "10px" }}
                          href="https://www.mettabrowser.com"
                          target="_blank"
                        >
                          Metta Browser&trade;
                        </a>
                      </li>
                      <li className="innerli2">
                        <a
                          style={{ paddingLeft: "10px" }}
                          href="https://www.mettapays.com"
                          target="_blank"
                        >
                          MettaPay&trade;
                        </a>
                      </li>
                      <li className="innerli2">
                        <a
                          style={{ paddingLeft: "10px" }}
                          href="https://www.mettapass.com"
                          target="_blank"
                        >
                          MettaPass&trade;
                        </a>
                      </li>
                      <li className="innerli2">
                        <a
                          style={{ paddingLeft: "10px" }}
                          href="https://www.mettaseed.com"
                          target="_blank"
                        >
                          MettaSeed&trade;
                        </a>
                      </li>
                      <li className="innerli2">
                        <a
                          style={{ paddingLeft: "10px" }}
                          href="https://www.mettatax.com"
                          target="_blank"
                        >
                          MettaTax&trade;
                        </a>
                      </li>
                      <li className="innerli2">
                        <a
                          style={{ paddingLeft: "10px" }}
                          href="https://www.mettasnap.com"
                          target="_blank"
                        >
                          MettaSnap&trade;
                        </a>
                      </li>
                      <li className="innerli2">
                        <a
                          style={{ paddingLeft: "10px" }}
                          href="https://www.mettarooms.com"
                          target="_blank"
                        >
                          Metta Hotel&trade;
                        </a>
                      </li>
                      <li className="innerli2">
                        <a
                          style={{ paddingLeft: "10px" }}
                          href="https://www.mettaproperties.com"
                          target="_blank"
                        >
                          Metta Properties&trade;
                        </a>
                      </li>
                      <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.heymetta.ai"
                      target="_blank"
                    >
                      heyMetta AI&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.mettadapp.com"
                      target="_blank"
                    >
                      MettaDApp&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://vcard.heymetta.com"
                      target="_blank"
                    >
                      Metta vCard&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.radiomettafm.com"
                      target="_blank"
                    >
                     Radio Metta FM &trade;
                    </a>
                  </li>
                    </div>
                  ) : null}
                </li>
                <li>
                  <a href="https://www.mettatoken.org">buy metta</a>
                </li>
                <li>
                  <a href="https://www.mettapass.com">Join Community</a>
                </li>
                <li>partners</li>
                <li onClick={() => navigate("/dao")}>Dao</li>
                <li>defi-camp</li>
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MobileHeader;
