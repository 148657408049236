import React from "react";
import Header from "../header/header";
import NavBar from "../header/NavBar";
import "./payment.css";
import { ImCheckboxChecked } from "react-icons/im";
import Subscribe from "../staking/Subscribe";
import Footer from "../footer/footer";

const Payment = () => {
  const plans = [
    {
      id: 1,
      name: "Basic Plan",
      price: 25,
    },
    {
      id: 2,
      name: "Silver Plan",
      price: 45,
    },
  ];
  return (
    <>
      <Header />
      <NavBar />
      <div className="papaDiv flex justify-center items-center mt-[5rem] flex-col mb-[10rem]">
        <div className="loginCard">
          <div className="top_card">
            <h1>GET STARTED TODAY</h1>
            <span>Signup Now</span>
          </div>
          <form>
            <div className="inputDiv">
              <label htmlFor="name">Fullname</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="eg: John Doe"
              />
            </div>
            <div className="inputDiv">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                name="name"
                id="name"
                placeholder="eg: johndoe@gmail.com"
              />
            </div>
            <div className="inputDiv">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="name"
                id="name"
                placeholder="min 8 characters"
              />
            </div>
            <div className="inputDiv">
              <label htmlFor="password">Plan Selected</label>
              <select name="cars" id="cars" form="carform">
                <option value="volvo">Choose...</option>
                <option value="saab">Basic</option>
                <option value="opel">Intermediate</option>
                <option value="audi">Advance</option>
              </select>
            </div>
            <div className="registerDiv">
              <button>Register Now</button>
            </div>
          </form>
        </div>
        <div>
          <div className="pricing_Section mt-[10rem] flex justify-center items-center flex-col w-[100%]">
            <h1 className="text-[3rem] font-extrabold text-white text-center">
              <span className="text-[#D12942] ">Training</span> Pricing
            </h1>
            <div className="pricing_Cards flex justify-center items-center mt-[5rem] gap-[3rem] flex-wrap lg:flex-nowrap ">
              {plans.map((plan) => (
                <>
                  <div className="anu bg-[#001841]">
                    <div className="pricing_Card w-[95%] lg:w-[350px]">
                      <h1>{plan.name}</h1>
                      <span>
                        ${plan.price}
                        <span className="text-[1.5rem]">/month</span>
                      </span>
                      <div className="pricing_Card_Body">
                        <ul>
                          <li>
                            <ImCheckboxChecked
                              color="#16C60C"
                              className="w-[1rem] h-[1rem]"
                            />
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.
                          </li>
                          <li>
                            <ImCheckboxChecked
                              color="#16C60C"
                              className="w-[.9rem] h-[.9rem] mt-1"
                            />
                            consectetur eius! Similique sed libero
                          </li>
                          <li>
                            <ImCheckboxChecked
                              color="#16C60C"
                              className="w-[1rem] h-[1rem]"
                            />
                            Illum veritatis dolore ea qui ullam hic reiciendis,
                            ab,
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="registerDiv flex justify-center items-center mb-3">
                      <button>Start Now</button>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <Subscribe />
      </div>
      <Footer />
    </>
  );
};

export default Payment;
