import React from "react";
import "./home.css";
import image1 from "../../assets/article3.jpg";
import image2 from "../../assets/mettateam.png";
import image3 from "../../assets/mettapay-concierge.png";
import { FaAngleRight } from "react-icons/fa";
const CardFour = () => {
  const data = [
    {
      id: 1,
      image: image3,
      title: "Transak X MettaPay",
      TopHeading: "Partnership",
      date: "Sep 23, 2022",
      description:
        "MettaPay Concierge is excited to announce our partnership with Transak! This partnership allows our community to buy and sell crypto in a safer and faster way.",
    },
    {
      id: 2,
      image: image2,
      title: "Executive Team",
      TopHeading: "Education",
      date: "Sep 12, 2022",
      description:
        "Our experienced team of experts is eager to assist you with any inquiries related to investing in METTA TOKEN, maximizing your travel experience, finding and purchasing your ultimate dream property.",
    },
    {
      id: 3,
      image: image1,
      title: "Multi-chain",
      TopHeading: "Announcement",
      date: "Sep 1, 2022",
      description:
        "Stake METTA and earn a share of the tokens purchased via auto- buyback. Safely secured within Metta Protocol’s on-chain NFT Stakes that are tradable, bridgeable, and transferable across 5 blockchains.",
    },
  ];
  return (
    <>
      <div className="cardFour_container">
        {data.map((item) => {
          return (
            <div className="inner_card_four">
              <div className="inner_card_four_img">
                <img src={item.image} alt="" />
                <div className="card_four_top_heading">
                  <span style={{ fontWeight: "600" }}>{item.TopHeading}</span>
                </div>
              </div>
              <div className="bottom_card_four">
                <p>{item.date}</p>
                <h1>{item.title}</h1>
                <p>{item.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CardFour;
