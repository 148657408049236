import { FaAngleRight } from "react-icons/fa";
import React from "react";
import Mettarectangle from "../../assets/Metta Browser.png";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CONFIG from "../../abi/config";
import { useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { GlobalContext } from "../../context/GlobalContext";
import "./MainBuyMettaCards.css";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import TokenInput from "./TokenInput";
import tokenList from "../../abi/tokenList.json";
import TokenListModal from "./TokenListModal";
import RouterAbi from "./../../abi/RouterAbi.json";
import TokenAbi from "./../../abi/token.json";

const providerOptions = {
  network: "mainnet",
  cacheProvider: false,
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: {
        97: process.env.REACT_APP_BSC_RPC,
      },
    },
  },
};

const MainBuyMettaCards = () => {
  const {
   account,
    error,
    errMsg,
    web3Provider,
    updateError,
    updateErrMsg,
    updateAccount,
    updateStakedBalance,
    updateRewardBalance,
    updateTokenBalance,
    updateWeb3Provider,
    fetchAccountData,
  } = useContext(GlobalContext);

  const [selFromToken, setSelFromToken] = useState(tokenList[0]);
  const [selToToken, setSelToToken] = useState(tokenList[1]);

  const [selFromValue, setSelFromValue] = useState("");
  const [selToValue, setSelToValue] = useState("");

  const [openFromTokenList, setOpenFromTokenList] = useState(false);
  const [openToTokenList, setOpenToTokenList] = useState(false);

  const handleWalletConnect = async () => {
    const web3modal = new Web3Modal({
      providerOptions,
    });
    const instance = await web3modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);
    updateWeb3Provider(provider);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    updateAccount(address);
    const network = await provider.getNetwork();
    console.log(network);
    if (network.chainId !== CONFIG.chainId) {
      updateError(true);
      updateErrMsg(
        `Contract is not deployed on current network. please choose ${CONFIG.networkName}`
      );
    } else {
      updateError(false);
      updateErrMsg("");
      fetchAccountData(provider);
      // loadAccountData(signer, address)
    }
  };

  const disconnectWallet = () => {
    updateAccount(null);
    updateStakedBalance({
      plan0: null,
      plan1: null,
      plan2: null,
      plan3: null,
      plan4: null,
    });
    updateRewardBalance({
      plan0: null,
      plan1: null,
      plan2: null,
      plan3: null,
      plan4: null,
    });
    updateTokenBalance(null);
    updateWeb3Provider(null);
  };

  const handleSwitch = () => {
    setSelToToken(selFromToken);
    setSelFromToken(selToToken);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (
      selToToken?.symbol.toLowerCase() === selFromToken?.symbol.toLowerCase()
    ) {
      console.log(selToToken);
      setSelFromToken(
        ...tokenList.filter(
          (item) =>
            item.symbol.toLowerCase() !== selToToken?.symbol.toLowerCase()
        )
      );
    }
  }, [selToToken]);

  useEffect(() => {
    if (
      selFromToken?.symbol.toLowerCase() === selToToken?.symbol.toLowerCase()
    ) {
      console.log(selFromToken);
      setSelToToken(
        ...tokenList.filter(
          (item) =>
            item.symbol.toLowerCase() !== selFromToken?.symbol.toLowerCase()
        )
      );
    }
  }, [selFromToken]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        updateAccount(accounts[0]);
        fetchAccountData(web3Provider);
      });
      window.ethereum.on("chainChanged", (chainId) => {
        window.location.reload();
      });
    }
  }, [account]);

  const getSwapAmount = async () => {
    if (account) {
      try {
        const amount = ethers.utils.parseUnits(
          selFromValue,
          selFromToken.decimals
        );
        console.log(amount.toString());
        const signer = web3Provider.getSigner();
        const contract = new ethers.Contract(
          CONFIG.UNISWAP_ROUTER_ADDRESS,
          RouterAbi,
          signer
        );
        const WETH = await contract.WETH();
        let path = [];
        if (selFromToken.symbol.toLowerCase() === "tbnb") {
          path[0] = WETH;
          path[1] = selToToken.address;
        } else {
          path[0] = selFromToken.address;
          path[1] = WETH;
        }
        const amountOut = await contract.getAmountsOut(amount.toString(), path);
        const swapAmount = ethers.utils.formatUnits(
          amountOut.toString().split(",")[1],
          selToToken.decimals
        );

        setSelToValue(swapAmount);
        return amountOut;
      } catch (e) {
        console.log(e);
      }
    } else {
      alert("Connect your wallet");
    }
  };

  const approve = async () => {
    if (account) {
      try {
        const amount = ethers.utils.parseUnits(
          selFromValue,
          selFromToken.decimals
        );
        console.log(amount.toString());
        const signer = web3Provider.getSigner();
        const contract = new ethers.Contract(
          selFromToken.address,
          TokenAbi,
          signer
        );
        const tx = await contract.approve(
          CONFIG.UNISWAP_ROUTER_ADDRESS,
          amount.toString()
        );
        await tx.wait();
      } catch (e) {
        console.log(e);
      }
    } else {
      alert("Connect your wallet");
    }
  };

  const swapExactEthforTokens = async () => {
    try {
      let estimatedSwapAmount = await getSwapAmount();
      const signer = web3Provider.getSigner();
      const contract = new ethers.Contract(
        CONFIG.UNISWAP_ROUTER_ADDRESS,
        RouterAbi,
        signer
      );
      const WETH = await contract.WETH();
      const path = [WETH, selToToken.address];
      const amount = ethers.utils.parseEther(selFromValue);
      let deadline = new Date().getTime() / 1000;
      estimatedSwapAmount = estimatedSwapAmount[1];
      console.log(estimatedSwapAmount.toString());
      deadline = Math.round(deadline + 86400);
      console.log(deadline);

      const tx = await contract.swapExactETHForTokens(
        estimatedSwapAmount.toString(),
        path,
        account,
        deadline,
        { value: amount.toString() }
      );

      await tx.wait();
    } catch (e) {
      console.log(e);
    }
  };

  const swapTokensforExactEth = async () => {
    try {
      let estimatedSwapAmount = await getSwapAmount();
      const signer = web3Provider.getSigner();
      const contract = new ethers.Contract(
        CONFIG.UNISWAP_ROUTER_ADDRESS,
        RouterAbi,
        signer
      );
      const WETH = await contract.WETH();
      const path = [selFromToken.address, WETH];
      const amount = ethers.utils.parseEther(selFromValue);
      let deadline = new Date().getTime() / 1000;
      estimatedSwapAmount = estimatedSwapAmount[1];
      console.log(estimatedSwapAmount.toString());
      deadline = Math.round(deadline + 86400);
      console.log(deadline);

      const tx = await contract.swapTokensForExactETH(
        estimatedSwapAmount.toString(),
        amount.toString(),
        path,
        account,
        deadline
      );

      await tx.wait();
    } catch (e) {
      console.log(e);
    }
  };

  const swap = async () => {
    if (selFromToken.symbol.toLowerCase() === "tbnb") {
      await swapExactEthforTokens();
    } else {
      await swapTokensforExactEth();
    }
  };

  return (
    <div>
      <div className="flex flex-col gap-5">
        <div className="bg-[#021335] rounded-2xl flex  items-center h-[80px] pl-5 pr-5 ">
          <div className=" flex justify-around items-center">
            <img src={Mettarectangle} alt="" className="h-[60px] w-[60px]" />
            <h1 className="font-extrabold text-[18px] text-white pl-2 pr-12">
              METTA Swap
            </h1>
          </div>
          {account ? (
            <button
              className="py-3 px-3 ml-4 uppercase font-medium group flex items-center justify-center space-x-2 rounded-2xl transition-all ease-in-out duration-[900ms] hover:scale-125"
              onClick={() => disconnectWallet()}
              style={{
                background: "rgb(247,130,69)",
                background:
                  "linear-gradient(90deg, rgba(247,130,69,1) 29%, rgba(208,38,66,1) 100%)",
              }}
            >
              <span className=" text-white truncate">
                {account.slice(0, 5) + "..." + account.slice(37, 42)}
              </span>
            </button>
          ) : (
            <button
              className="py-3 px-3 ml-4 uppercase font-medium group flex items-center justify-center space-x-2 rounded-2xl transition-all ease-in-out duration-[900ms] hover:scale-125"
              onClick={() => handleWalletConnect()}
              style={{
                background: "rgb(247,130,69)",
                background:
                  "linear-gradient(90deg, rgba(247,130,69,1) 29%, rgba(208,38,66,1) 100%)",
              }}
            >
              <span className="text-white truncate">Connect</span>
            </button>
          )}
        </div>
        <div className="relative justify-center items-center">
          <div className="flex flex-col  bg-[#021335] rounded-2xl pt-3 pb-3 ">
            <div className="flex flex-col justify-center items-center">
              <div className="flex flex-col justify-items-end justify-end items-end w-[90%]">
                <h1 className="font-extrabold text-[15px] text-[#8899C6]">
                  Available:
                </h1>
              </div>
              <TokenInput
                selToken={selFromToken}
                value={selFromValue}
                setValue={setSelFromValue}
                disabled={false}
                openList={setOpenFromTokenList}
                onBlur={getSwapAmount}
              />

              <div className="w-[100%] flex justify-center items-center gap-2 flex-wrap mt-3">
                <button className="btnCARD bg-[#070f21] w-[90px] pt-2 pb-2 pr-2 pl-2 text-[#8899C6] font-extrabold rounded-xl">
                  25%
                </button>
                <button className="btnCARD bg-[#070f21] w-[90px] pt-2 pb-2 pr-2 pl-2 text-[#8899C6] font-extrabold rounded-xl">
                  50%
                </button>
                <button className="btnCARD bg-[#070f21] w-[90px] pt-2 pb-2 pr-2 pl-2 text-[#8899C6] font-extrabold rounded-xl">
                  75%
                </button>
                <button className="btnCARD bg-[#070f21] w-[90px] pt-2 pb-2 pr-2 pl-2 text-[#8899C6] font-extrabold rounded-xl">
                  MAX%
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
          <div className="circleBTNcontainer flex justify-center items-center w-[100%] absolute  top-[10rem]">
            <button
              className="circleBTN flex justify-center items-center  cursor-pointer transition-all duration-300 ease-in-out hover:scale-110"
              onClick={handleSwitch}
            >
              <FaArrowDown className=" text-[#8899c6]" />
              <FaArrowUp className=" text-[#8899c6]" />
            </button>
          </div>
          <div className="flex flex-col gap-5 bg-[#021335] rounded-2xl pt-3 pb-3 mt-3 ">
            <div className="flex flex-col justify-center items-center">
              <div className="flex flex-col justify-items-end justify-end items-end w-[90%] mt-7">
                <h1 className="font-extrabold text-[15px] text-[#8899C6]">
                  Balance:
                </h1>
              </div>
              <TokenInput
                selToken={selToToken}
                value={selToValue}
                setValue={setSelToValue}
                disabled={true}
                openList={setOpenToTokenList}
              />
              <div className="w-[100%] flex justify-center items-center gap-2 flex-wrap mt-5">
                <button
                  className="btnCARD bg-[#070f21] w-[110px] pt-2 pb-2 pr-2 pl-2 text-[#8899C6] font-extrabold rounded-xl"
                  onClick={approve}
                >
                  Approve
                </button>
                <button
                  className="btnCARD bg-[#070f21] w-[110px] pt-2 pb-2 pr-2 pl-2 text-[#8899C6] font-extrabold rounded-xl"
                  onClick={swap}
                >
                  Swap
                </button>
              </div>
              <br />
              <div className="flex justify-center items-center">
                <h1 className="font-extrabold text-[15px] text-[#8899C6]">
                  Powered by METTA PROTOCOL
                </h1>
              </div>
            </div>
          </div>
          <TokenListModal
            show={openToTokenList}
            handleClose={setOpenToTokenList}
            handleChange={setSelToToken}
          />
          <TokenListModal
            show={openFromTokenList}
            handleClose={setOpenFromTokenList}
            handleChange={setSelFromToken}
          />
        </div>
      </div>
    </div>
  );
};

export default MainBuyMettaCards;
