import React from "react";
import "./header.css";
import { FaWallet } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CONFIG from "../../abi/config";


const Header = () => {
  const {
    account,
    web3Provider,
    updateError,
    updateErrMsg,
    updateAccount,
    updateStakedBalance,
    updateRewardBalance,
    updateTokenBalance,
    updateWeb3Provider,
    fetchAccountData,
  } = useContext(GlobalContext);
 
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      rpc: {
        56: process.env.REACT_APP_BSC_RPC,
    }
  }
}
 const handleWalletConnect = async () => {
    const web3modal = new Web3Modal({
      providerOptions,
    });
    const instance = await web3modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);
    updateWeb3Provider(provider);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    updateAccount(address);
    const network = await provider.getNetwork();
    console.log(network);
    if (network.chainId !== CONFIG.chainId) {
      updateError(true);
      updateErrMsg(
        `Contract is not deployed on current network. please choose ${CONFIG.networkName}`
      );
    } else {
      updateError(false);
      updateErrMsg("");
      fetchAccountData(provider);
      // loadAccountData(signer, address)
    }
  };

  const disconnectWallet = () => {
    updateAccount(null);
    updateStakedBalance({
      plan0: null,
      plan1: null,
      plan2: null,
      plan3: null,
      plan4: null,
    });
    updateRewardBalance({
      plan0: null,
      plan1: null,
      plan2: null,
      plan3: null,
      plan4: null,
    });
    updateTokenBalance(null);
    updateWeb3Provider(null);
  };

  return (
    <>
      <div className="banner-top">
        <div>
          <span className="btn-white"> Breaking News </span>
          <p className="">
            Metta Protocol’s Metta Motors raised $27M to build the future of car
            rentals.
          </p>
        </div>
        {account ? (
          <button className="btn-gradient"  onClick={() => disconnectWallet()}>
          <span className="icon">
            <FaWallet />
          </span>
          <span>{account.slice(0, 5) + "..." + account.slice(37, 42)}</span>
        </button>
        ) : (
        <button className="btn-gradient"  onClick={() => handleWalletConnect()}>
          <span className="icon">
            <FaWallet />
          </span>
          <span>Connect Wallet</span>
        </button>
        )}
      </div>
    </>
  );
};

export default Header;
