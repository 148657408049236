import { RxCross2 } from "react-icons/rx";
import tokenList from "./../../abi/tokenList.json";

const TokenListModal = ({ show, handleClose, handleChange }) => {
  const changeToken = (token) => {
    handleChange(token);
    handleClose(false);
  };

  if (show) {
    return (
      <div className="absolute -top-16 right-0 w-full bg-[#01194b] h-[500px] text-[#8899C6] rounded-xl">
        <div className="header p-6 pb-5 flex items-center justify-between">
          <h1 className="font-bold uppercase">Select Asset</h1>
          <button onClick={() => handleClose(false)}>
            <RxCross2 className="text-[#8899c6]" size="" />
          </button>
        </div>

        {tokenList.map((token, i) => {
          return (
            <div
              key={i}
              className="bg-gradient-to-r from-[#001238] to-[#011642] border-b border-[#001c59] cursor-pointer"
              onClick={() => changeToken(token)}
            >
              <div className="flex items-center px-10 py-4">
                <div className="w-10">
                  <img src={token.logoURI} alt="" />
                </div>
                <span className="ml-4 font-bold">{token.symbol}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
};

export default TokenListModal;
