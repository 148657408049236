import React from "react";
import "./home.css";
import image1 from "../../assets/metta-secure.png";
import image2 from "../../assets/metta-friendly.png";
import image3 from "../../assets/metta-multichain.png";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
const CardOne = () => {
  const data = [
    {
      id: 1,
      image: image1,
      title: "Security",
      description:
        "End-to-end encryption, private keys are never shared within our ecosystem.",
      link: "https://mettamotors.com/",
    },
    {
      id: 2,
      image: image2,
      title: "User Friendly",
      description: "Designed to enable a frictionless end user experience",
      link: "https://mettarooms.com/",
    },
    {
      id: 3,
      image: image3,
      title: "Multi-chain",
      description:
        "Support for all chains and rollups out-of-the-box. Bridge in & out.",
      link: "https://mettaproperties.com/",
    },
  ];
  return (
    <>
      <div className="contianer_card">
        {data.map((item) => {
          return (
            <div className="cardOneContainer">
              <div className="imagCardContainer">
                <img src={item.image} alt="" />
              </div>
              <h1>{item.title}</h1>
              <p>{item.description}</p>
              <a href={item.link}>
                <button className="cardOneBtnBTN flex justify-center items-center">
                  Launch DAPP
                  <span className="span_2">
                    <FaAngleRight
                      style={{
                        marginLeft: "1rem",
                        height: "1.5rem",
                        width: "1rem",
                      }}
                    />
                  </span>
                </button>
              </a>
              <div className="learnMore m-1">
                <h1>Learn More</h1>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CardOne;
