export const AppReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_TOKEN_BALANCE":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          TokenBalance: action.payload,
        },
      };

    case "UPDATE_ERROR":
      return {
        ...state,
        error: action.payload,
      };

    case "UPDATE_ERR_MSG":
      return {
        ...state,
        errMsg: action.payload,
      };

    case "UPDATE_STAKED_BALANCE":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          StakeBalance: action.payload,
        },
      };

    case "UPDATE_REWARDS_BALANCE":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          RewardBalance: action.payload,
        },
      };

    case "UPDATE_TOKEN_PRICE":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          TokenPrice: action.payload,
        },
      };

    case "UPDATE_TOTAL_REWARDS":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          TotalRewards: action.payload,
        },
      };

    case "UPDATE_TOTAL_STAKED":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          TotalStaked: action.payload,
        },
      };

    case "UPDATE_APY":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          apy: action.payload,
        },
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload,
      };

    case "UPDATE_PROVIDER":
      return {
        ...state,
        web3Provider: action.payload,
      };
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        cost: action.payload.cost,
        maxSupply: action.payload.maxSupply,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    case "UPDATE_BNB_BALANCE":
      return {
        ...state,
        bnbBalance: action.payload,
      };
    case "UPDATE_METTA_TOKEN_BALANCE":
      return {
        ...state,
        mettaTokenBalance: action.payload,
      };
    case "UPDATE_TERMS_AND_CONDITION":
      return {
        ...state,
        termsAndCondition: action.payload,
      };
    default:
      return state;
  }
};
