import React from "react";
import "./main.css";
import { FaAngleRight } from "react-icons/fa";

const MainBuyMetta = () => {
  return (
    <div>
      <div className="flex flex-col gap-4 ">
        <div className="left-container">
          <div className="inside">
            <p className="h1-main mr-10 font-[900]">
              The METTA <br /> Token
            </p>
            <h1 className="font-extrabold text-[23px] text-white">
              POWERING THE METTA ECOSYSTEM
            </h1>
            <p className="text-white text-[18px] w-[90%] md:w-[69%]  pt-4">
              The METTA Protocol{" "}
              <span className="text-white font-extrabold">(METTA TOKEN) </span>
              is a multi-chain that serves as the utility token for cross-chain
              transactions and interactions with the METTA Protocol.
            </p>
            <div className="flex flex-col flex-wrap mt-5 w-[90%] md:w-[59%]">
              <div className="flex gap-5 flex-wrap mt-5">
                <button className="gradiantBTN bg-[#021335] flex h-12 items-center pl-5 pr-5 gap-4 text-[#d02642] ">
                  GET METTA <FaAngleRight className="pl-2" />
                </button>
                <button className="gradiantBTN-2 bg-[#021335] flex h-12 items-center pl-[14%] gap-4 text-white w-[17.2rem] ">
                  BRIDGE METTA <FaAngleRight className="pl-2" />
                </button>
              </div>
              <button className="gradiantBTN-3 bg-[#021335] flex h-12 items-center text-center pl-[35%]  gap-4 text-white mt-3 ">
                STAKE METTA <FaAngleRight className="pl-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBuyMetta;
