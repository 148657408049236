import React from 'react'
import { useState } from 'react';
import { stakingData } from './stakingData';
import StakeTimerComponent from './StakeTimerComponent';
import useFetchNfts from '../../hooks/useFetchNfts';
import { prepareNftPath } from './helpers';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ethers } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import CONFIG from '../../abi/config';
import nftAbi from "../../abi/nft.json";
import nftStakingAbi from "../../abi/nft_staking.json";
import { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import useFetchStakedNfts from '../../hooks/useFetchStakedNfts';
import LoadingComponent from './LoadingComponent';


const NFTList = ({account, setFetchNfts, fetchNFTs, setFetchStakedNFTs, fetchStakedNFTs}) => {
const MySwal = withReactContent(Swal)
        const {
    error,
    errMsg,
    web3Provider,
    updateError,
    updateErrMsg,
    updateAccount,
    updateStakedBalance,
    updateRewardBalance,
    updateTokenBalance,
    updateWeb3Provider,
    fetchAccountData,
  } = useContext(GlobalContext);
        const nfts = useFetchNfts(account, fetchNFTs, setFetchNfts)
        console.log("NFTSSSS", nfts)
        const [selectedNFTs, setSelectedNFTs] = useState([])
        
        const stNfts = useFetchStakedNfts(web3Provider, account, fetchStakedNFTs, setFetchStakedNFTs)
        console.log("STAKED NFTSSSS", stNfts)
            const [loading, setLoading] = useState(false)
     // const stNfts = useStakedNFT( fetchNFTs, setFetchNfts)

  const [stake, setStake] = useState(false);
  const handleClick = () => {
    setStake(true);
    setMyNfts(false);
    setSelectedNFTs([])
  };
  const [myNfts, setMyNfts] = useState(true);
  const handleMyNfts = () => {
    setMyNfts(true);
    setStake(false);
  };

  const [tenureOne, setTenureOne] = useState(false);
  const [tenureTwo, setTenureTwo] = useState(false);
  const [tenureThree, setTenureThree] = useState(false);
  const [tenureFour, setTenureFour] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(0);

  const setPlanThirty = () => {
    setTenureOne(!tenureOne);
    setTenureTwo(false);
    setTenureThree(false);
    setTenureFour(false);
    setSelectedPeriod(0);
  };
  const setPlanSixty = () => {
    setTenureOne(false);
    setTenureTwo(!tenureTwo);
    setTenureThree(false);
    setTenureFour(false);
    setSelectedPeriod(1);
  };
  const setPlanNinety = () => {
    setTenureOne(false);
    setTenureTwo(false);
    setTenureThree(!tenureThree);
    setTenureFour(false);
    setSelectedPeriod(2);
  };
  const setPlanOneTwenty = () => {
    setTenureOne(false);
    setTenureTwo(false);
    setTenureThree(false);
    setTenureFour(!tenureFour);
    setSelectedPeriod(3);
  };
  const checkBoxClick = (e, tokenId) => {
    console.log(tokenId)
        const token_id = tokenId.toString();
        console.log(parseInt(token_id))
        if (e.target.checked) {
            setSelectedNFTs([...selectedNFTs, token_id])
        } else {
            setSelectedNFTs(selectedNFTs.filter((item) => item !== token_id))
        }
       
       console.log(selectedNFTs)
       
    }
  const checkBoxClickStaked = (e, tokenId) => {
    console.log(tokenId)
        const token_id = tokenId.toString();
        console.log(parseInt(token_id))
        if (e.target.checked) {
            setSelectedNFTs([...selectedNFTs, token_id])
        } else {
            setSelectedNFTs(selectedNFTs.filter((item) => item !== token_id))
        }
       
       console.log(selectedNFTs)
       
    }
    const unStake = async () => { 
        try {
          setLoading(true)
            if(selectedNFTs.length === 0){
                 MySwal.fire({
                    position: 'top-end',
                    title: 'Error!',
                    text: 'No nft selected to unstake',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoading(false)
                return;
            }
            const signer = web3Provider.getSigner()
            const stakingContract = new ethers.Contract(CONFIG.NFT_STAKING_CONTRACT, nftStakingAbi, signer)
            const estimateGas = await stakingContract.estimateGas._unstakeMany(selectedNFTs)
            console.log(estimateGas.toString())
            const tx = {
                gasLimit: estimateGas.toString(),
            }
            const stakingTx = await stakingContract._unstakeMany(selectedNFTs, tx)
            await stakingTx.wait()
            setFetchNfts(true)
            setLoading(false)
            MySwal.fire({
                position: 'top-end',
                title: 'success',
                text: 'Unstaking done',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            })
            setSelectedNFTs([])
            console.log(setFetchStakedNFTs)
            setFetchStakedNFTs(true)
            setFetchNfts(true)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
     console.log(selectedNFTs)
     const clearCheckboxes = () => {
        Array.from(document.querySelector('.stake-nfts').querySelectorAll('img.image-tick')).map(item => {
            item.style.display = "none"
        })
        Array.from(document.querySelector('.staked').querySelectorAll('img.image-tick')).map(item => {
            item.style.display = "none"
        })
    }
     const stakeNfts = async () => { 
        try {
          setLoading(true)
            console.log(selectedNFTs)
            if(selectedNFTs.length ===0){
                 MySwal.fire({
                    position: 'top-end',
                    title: 'Error!',
                    text: 'No nft selected to stake',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoading(false)
                return;
            }
            const signer = web3Provider.getSigner()
            const stakingContract = new ethers.Contract(CONFIG.NFT_STAKING_CONTRACT, nftStakingAbi, signer)
            const estimateGas = await stakingContract.estimateGas.stake(selectedNFTs, selectedPeriod)
             const tx = {
                gasLimit: estimateGas.toString()
            }
            let stakingTx;
            stakingTx= await stakingContract.stake(selectedNFTs, selectedPeriod, tx)
            await stakingTx.wait()
            setLoading(false)
             MySwal.fire({
                position: 'top-end',
                title: 'success',
                text: 'Staking done',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            })
            setFetchNfts(true)
            setSelectedNFTs([])
            setFetchStakedNFTs(true)
            // clearCheckboxes()
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
     }
     const approvedNFT = async () => { 
        try {
          setLoading(true)
            console.log(selectedNFTs)
            if (selectedNFTs.length === 0 ) {
                
                MySwal.fire({
                    position: 'top-end',
                    title: 'Error!',
                    text: 'No nft selected to stake',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500
                })
                // alert('no nft selected to stake')
                setLoading(false)
                return;
            }
            
            const signer = web3Provider.getSigner()
            const nftContract = new ethers.Contract(CONFIG.NFT_CONTRACT_ADDRESS, nftAbi, signer)
            const estimateGas = await nftContract.estimateGas.setApprovalForAll(CONFIG.NFT_STAKING_CONTRACT, true)
            const tx = {
                gasLimit: estimateGas.toString()
            }
            const approveTx = await nftContract.setApprovalForAll(CONFIG.NFT_STAKING_CONTRACT, true, tx)
            await approveTx.wait()
            await stakeNfts()
        
        } catch (error) {
          setLoading(false)
                console.log(error)
        }
     }
    const handleApproval = async () => {
        try {
            setLoading(true)
            const signer = web3Provider.getSigner();
            const nftContract = new ethers.Contract(CONFIG.NFT_CONTRACT_ADDRESS, nftAbi, signer)
            const isApproval = await nftContract.isApprovedForAll(account, CONFIG.NFT_STAKING_CONTRACT)
            
            if(isApproval){
                await stakeNfts()
            }
            else{
                await approvedNFT()
            }
        } catch (error) {
          setLoading(false)
            console.log(error)
        }
    }
    
  return (
    <>
           {loading && (<LoadingComponent />)}
            <div className="flex gap-5 justify-center items-center mt-4 cursor-pointer animate__animated animate__fadeInDown">
              <h1
                className={
                  myNfts
                    ? "font-bold text-[15px] underline text-[#8ba1f1] font-uppercase"
                    : "font-bold text-[15px] text-[#8ba1f1] font-uppercase"
                }
                onClick={handleMyNfts}
              >
                MY NFTS
              </h1>
              <h1
                className={
                  stake
                    ? "font-bold text-[15px] underline text-[#8ba1f1] font-uppercase"
                    : "font-bold text-[15px] text-[#8ba1f1] font-uppercase"
                }
                onClick={handleClick}
              >
                STAKED
              </h1>
            </div>
            {myNfts && (
              <>
                <div className="flex flex-col mt-20 justify-center gap-5 items-center  animate__animated animate__fadeInDown">
                  <div className="containerStaked">
                    {/* <div className="h-[1px] w-[300px] bg-[#D43143] mt-2" /> */}
                    {nfts?.length > 0 ? nfts.map((item, index) => {
                      return (
                        <>
                          <div>
                            <div className="stakingCard">
                              <div className="innerDiv">
                                <div className="flex justify-center items-center gap-4">
                                  <input
                                    className="form-check-input CheckBox"
                                    type="checkbox"
                                    value=""
                                    checked={selectedNFTs.includes(item.token_id)}
                                    id="flexCheckDefault"
                                    onClick={e => checkBoxClick(e, item.token_id)}
                                  />
                                  <div className="stakingCardImg">
                                    <img src={item?.normalized_metadata?.image ? prepareNftPath(item?.normalized_metadata?.image) : 'https://via.placeholder.com/90x90?text=NFT'} alt="" />
                                  </div>
                                  <div>
                                    <h1 className="text-[white] font-bold mt-6">
                                      {item.token_id}
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="h-[1px] w-[100%] bg-[#D43143] mt-2" />
                          </div>
                        </>
                      );
                    }) : nfts?.length === 0 &&
                      <>
                       <div className='flex justify-start items-center'>
                           <h1 className=' text-white'>
                                 No NFTs Found
                           </h1>
                       </div>
                      </>
                    }
                  </div>
                </div>
                <div className="flex  mt-20 justify-center flex-wrap gap-5 items-center  animate__animated animate__fadeInDown">
                  <hr className="linesNFT text-[#8ba1f1]  w-[180px] animate__animated animate__fadeInDown" />
                  <h1 className="font-bold text-[15px] text-[#8ba1f1] font-uppercase">
                    Choose Staking period in Days
                  </h1>
                  <div className="flex gap-2 justify-center items-center flex-wrap">
                    <button
                      className={tenureOne ? "stakeBTNSActive" : "stakeBTNS"}
                    >
                      <h1 className="tenure" onClick={setPlanThirty}>
                        30
                      </h1>
                    </button>
                    <button
                      className={tenureTwo ? "stakeBTNSActive" : "stakeBTNS"}
                    >
                      <h1 className="tenure" onClick={setPlanSixty}>
                        60
                      </h1>
                    </button>
                    <button
                      className={tenureThree ? "stakeBTNSActive" : "stakeBTNS"}
                    >
                      <h1 className="tenure" onClick={setPlanNinety}>
                        90
                      </h1>
                    </button>
                    <button
                      className={tenureFour ? "stakeBTNSActive" : "stakeBTNS"}
                    >
                      <h1 className="tenure" onClick={setPlanOneTwenty}>
                        120
                      </h1>
                    </button>
                    <button className="stake" onClick={handleApproval}>
                      <h1 className="tenureStake">STAKE MY NFTS</h1>
                    </button>
                  </div>
                  <hr className="linesNFT text-[#8ba1f1]  w-[180px] animate__animated animate__fadeInDown" />
                </div>
              </>
            )}
            {stake && (
              <>
                <div className="flex flex-col mt-20 justify-center gap-5 items-center  animate__animated animate__fadeInDown">
                  <div className="containerStaked">
                    {/* <div className="h-[1px] w-[300px] bg-[#D43143] mt-2" /> */}
                    {stNfts?.length > 0 ? stNfts.map((item, index) => {
                      return (
                        <>
                          <div>
                            <div className="stakingCard">
                              <div className="innerDiv">
                                <div className="flex justify-center items-center gap-4">
                                  <input
                                    className="form-check-input CheckBox"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onClick={e => checkBoxClickStaked(e, item.tokenId)}
                                  />
                                  <div className="stakingCardImg">
                                    <img src="favicon.png" alt="" />
                                  </div>
                                  <div>
                                    <h1 className="text-[white] font-bold mt-6">
                                     {item.tokenId}
                                    </h1>
                                  </div>
                                </div>
                              </div>
                              <p className="text-white mt-6">
                                <StakeTimerComponent startDate={item.timestamp._hex} />
                              </p>
                            </div>
                            <div className="h-[1px] w-[100%] bg-[#D43143] mt-2" />
                          </div>
                        </>
                      );
                    })
                      : stNfts?.length === 0 &&
                      <>
                       <div className='flex justify-start items-center'>
                           <h1 className=' text-white'>
                                    No staked NFTs Found
                                    </h1>
                                    </div>
                                    </>
                                    }
                  </div>
                </div>
                <div className="flex  mt-20 justify-center flex-wrap gap-5 items-center  animate__animated animate__fadeInDown">
                  <hr className="linesNFT text-[#8ba1f1]  w-[180px] animate__animated animate__fadeInDown" />
                  <div className="flex gap-2 justify-center items-center flex-wrap">
                    <button className="stake" onClick={unStake}>
                      <h1 className="tenureStake">UNSTAKE</h1>
                    </button>
                  </div>
                  <hr className="linesNFT text-[#8ba1f1]  w-[180px] animate__animated animate__fadeInDown" />
                </div>
              </>
            )}
          </>
  )
}

export default NFTList