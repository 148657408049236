import React from "react";
import "./buyMetta.css";

import MainBuyMetta from "./MainBuyMetta";
import MainBuyMettaCards from "./MainBuyMettaCards";
import Card from "./Card";
import Footer from "../footer/footer";
import Subscribe from "../staking/Subscribe";
import NavBar from "../header/NavBar";
import Header from "../header/header";

const BuyMetta = () => {
  return (
    <>
      <div className="BuyMettaBackGround">
        <Header />
        <NavBar />
        <div className="flex justify-center items-center flex-wrap mt-[0%] pb-3 gap-y-10 md:mt-[5%] lg:mt-[8%] animate__animated animate__fadeInDown ">
          <MainBuyMetta />
          <MainBuyMettaCards />
        </div>
      </div>
      <div className="">{/* <Cards /> */}</div>
      <Subscribe />
      <Footer />
    </>
  );
};

export default BuyMetta;
