import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import { IoDocumentsOutline, IoMail } from "react-icons/io5";
import { GlobalContext } from "./context/GlobalContext";
import axios from "axios";

const TermsAndConditionCheck = (termsAndCondition) => {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const { updateTermsAndCondition } = useContext(GlobalContext);
  const handleAccept = () => {
    updateTermsAndCondition(true);
    console.log("termsAndCondition", termsAndCondition);
    try {
      fetch("https://api.ipify.org?format=jsonp?callback=?", {
        method: "GET",
        headers: {},
      })
        .then((res) => {
          return res.text();
        })
        .then((ip) => {
          console.log("My public IP address is: ", ip);
          axios
            .post(
              "https://sheet.best/api/sheets/c498b6aa-614a-4ef8-b58f-692b65d7f13c",
              {
                mode: "cors",
                headers: {
                  "Content-Type": "application/json",
                },
                IP_ADDRESSES: ip,
              }
            )
            .then((resp) => {
              console.log(resp);
            });
        });
    } catch (error) {
      console.log("error", error);
      alert(error);
    }
  };
  const handleDecline = () => {
    window.location.href = "https://www.google.com";
  };

  const [isMediumScreen, setIsMediumScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMediumScreen(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {termsAndCondition && (
        <div className="modal">
          <div className="modal_inner">
            <div className="modal_header flex justify-center items-center">
              <IoDocumentsOutline className="icon_term text-5xl" />
            </div>
            <div className="flex justify-center items-center mt-5">
              <hr className=" hr_line w-[8rem] h-[2px] mr-2  " />
              <h1>
                <span className="text-md">Terms & Conditions</span>
              </h1>

              <hr className="hr_line w-[8rem] h-[2px] ml-2 " />
            </div>
            <iframe
              src="https://mettastream.com/embed/YBPj4FwNWHhJcn7?color=f204aa"
              frameborder="0"
              width={isMediumScreen ? "280" : "100%"}
              height={isMediumScreen ? "200" : "400"}
              allowfullscreen
            ></iframe>

            <div className="flex flex-col justify-start items-start pt-10">
              <p className="text-sm">
                Introduction Metta Protocol LLC ("Metta Protocol", "we", "us",
                or "our") values your privacy and is committed to protecting
                your personal information. This privacy policy ("Policy")
                describes how we collect, use, and share information when you
                use our website located at <b>www.mettaprotocol.io </b>("Site").
                By using our Site, you consent to the practices described in
                this Policy.
              </p>
              <br />
              <p className="text-sm">
                Welcome to Metta Protocol, where we are dedicated to
                revolutionizing the blockchain industry through innovation,
                integrity, and practical solutions. Our focus is on creating a
                more accessible and relevant technology that serves real
                businesses, their customers, and communities in regions that
                require support. We believe in the power of blockchain
                technology to transform the world, and we are committed to
                delivering value to our investors through transparency,
                security, and efficiency.
              </p>
              <br />
              <p className="text-sm">
                Investing in any type of asset carries a certain level of risk,
                and cryptocurrency investments are no exception. Metta Protocol
                recognizes the risks associated with cryptocurrency investments,
                including market volatility and fluctuations in price.
              </p>
              <br />
              <p className="text-sm">
                While we are committed to providing our investors with accurate
                and reliable information about our token and its potential
                value, we cannot guarantee returns or specific investment
                outcomes, and all investment decisions should be made
                independently and with the understanding that they carry risk.
                By investing in Metta Protocol Token, our investors acknowledge
                that they have read and understood the risks associated with
                cryptocurrency investments, and that they have undertaken
                independent research and sought professional advice if
                necessary. Metta Protocol is not responsible for any investment
                decisions made by its investors, and all investment decisions
                should be made independently and with the understanding that
                they carry risk. We encourage all investors to carefully
                consider the risks associated with cryptocurrency investments
                and to approach all investments with care. At Metta Protocol, we
                remain committed to providing our investors with the highest
                level of transparency and accuracy, and we believe that by
                working together to educate ourselves and others about the
                potential of blockchain technology, we can help to build a more
                stable and successful industry for everyone.
              </p>
              <br />

              <p className="text-sm">
                We encourage all visitors and potential investors to approach
                cryptocurrency investments with care and to thoroughly educate
                themselves about the technology, risks, and potential rewards
                before making any investment decisions. It is important to
                understand that Metta Protocol is not responsible for any
                investment decisions made by its investors. While we are
                committed to providing our customers with transparency and
                accuracy in all of our operations, including our token sale, we
                cannot guarantee returns or specific investment outcomes. All
                investment decisions should be made independently and with the
                understanding that they carry risk. We believe in building trust
                with our investors through clear communication and by providing
                reliable information about our token and its potential value. We
                encourage all visitors to learn more about Metta Protocol and
                cryptocurrency in general, and to seek professional advice if
                necessary. By working together to educate ourselves and others
                about the potential of blockchain technology, we can help to
                build a more stable and successful industry for everyone.
              </p>
              <br />
              <p className="text-sm">
                Investing in Metta Protocol's ICO offers several benefits for
                potential investors. One of the main benefits is that investors
                are buying the token at the lowest price of $0.01, which means
                they will be the first to make profits and getting more tokens
                for their dollars. The token will never go lower than that and
                is expected to increase in value over time. In terms of
                security, Metta Protocol has taken several measures to protect
                investors. There will be no venture capitalist or whale from the
                ICO phase, which reduces the risk of pump and dump schemes. This
                is because we did not have a pre-sale event or air drop, and we
                carefully set a full ICO sales limit so that no one can buy more
                than $5000 worth of tokens during the ICO phase. This limit is
                in place to protect METTA from having a whale from the ICO. In
                addition, we conduct a full KYC (Know Your Customer) process to
                ensure that we know all our investors and to prevent any bad
                actors from participating in our ICO. By taking these steps, we
                aim to create a more transparent and fair ICO process that is
                accessible to all investors. Overall, investing in Metta
                Protocol's ICO offers a unique opportunity to participate in a
                fair and transparent ICO process, while also potentially
                benefiting from the growth and success of our innovative
                blockchain platform. By investing at the lowest price, investors
                can potentially benefit from significant returns as the value of
                the token increases over time.
              </p>
              <br />
              <p className="text-sm">
                Investing in Initial Coin Offerings (ICO) carries a high degree
                of risk, and potential investors should carefully consider the
                risks and uncertainties associated with this type of investment
                before making a decision to invest. ICOs are highly speculative
                and unregulated investments that can result in the total loss of
                your investment. Some of the risks associated with ICO
                investments include but are not limited to: market volatility,
                limited liquidity, lack of regulation, potential for fraud, and
                a high degree of uncertainty regarding the underlying technology
                and project. Metta Protocol acknowledges these risks and
                encourages potential investors to carefully consider their
                investment goals and risk tolerance before investing in our ICO.
                We also strongly recommend that potential investors consult with
                their financial advisors, lawyers, and other professionals
                before making any investment decisions.
              </p>
              <br />
              <p className="text-sm">
                By investing in our ICO, you acknowledge that you have read and
                understood the risks associated with ICO investments, and that
                you have undertaken independent research and sought professional
                advice if necessary. Metta Protocol is not responsible for any
                investment decisions made by its investors, and all investment
                decisions should be made independently and with the
                understanding that they carry risk.
              </p>
              <br />
              <p className="text-sm">
                The Metta Protocol ecosystem includes 25+ utilities, which are
                not owned by Metta Protocol LLC. Many of these utilities are
                partnerships and have no direct affiliation with Metta Protocol
                LLC. In the event of any legal dispute or challenge, investors
                must acknowledge and understand that no representative of Metta
                Protocol LLC will travel from Dade County to another state for
                any legal proceedings or obligations.
              </p>
              <br />
              <p className="text-sm">
                Investors should be aware that they are entering into a legal
                agreement with Metta Protocol LLC, and any legal proceedings
                will be governed by the laws of Dade County. Prior to investing
                in Metta Protocol Token, investors must read and agree to these
                terms and conditions, which are presented as a popup on our
                website. By acknowledging and agreeing to these terms, investors
                consent to the jurisdiction of any Dade County courthouse, and
                any legal cases must be resolved in accordance with the laws and
                regulations of Dade County. It is the responsibility of each
                investor to review and understand these terms and conditions
                before investing in Metta Protocol Token. Failure to read or
                acknowledge these terms may result in the automatic dismissal of
                any legal claims or challenges by any Dade County courthouse. No
                investor may proceed with investment in Metta Protocol Token
                without first agreeing to these terms and conditions.
              </p>
              <br />
              <p className="text-sm">
                Furthermore, investors must understand that any legal action
                taken against Metta Protocol LLC must be made in good faith and
                with the intent to resolve disputes in a fair and reasonable
                manner. Any frivolous or baseless legal claims will be
                considered a violation of these terms and conditions, and may
                result in legal action being taken against the investor.
              </p>
              <br />
              <p className="text-sm">
                Investors should also be aware that investing in Metta Protocol
                Token carries inherent risks, and they should carefully consider
                their investment before committing any funds. Metta Protocol LLC
                cannot guarantee returns or specific investment outcomes, and
                all investment decisions should be made independently and with
                the understanding that they carry risk.
              </p>
              <br />
              <p className="text-sm">
                By entering this website, visitors acknowledge and understand
                that they are entering a cryptocurrency investment opportunity
                website, and that all investments carry risks. Visitors are
                responsible for understanding and assuming all risks associated
                with cryptocurrency investments, including market volatility,
                technological advancements, regulatory changes, and other
                external factors. It is important for visitors to carefully
                consider their investment and approach cryptocurrency
                investments with care and caution.
              </p>
              <br />
              <p className="text-sm">
                In conclusion, Metta Protocol LLC is committed to providing
                transparency and accuracy in all of our operations, including
                our ICO process. We encourage all potential investors to read
                and understand these terms and conditions before investing in
                Metta Protocol Token, and to seek professional advice if
                necessary. By investing in Metta Protocol Token, investors
                acknowledge and agree to these terms and conditions, and consent
                to the jurisdiction of any Dade County courthouse for any legal
                proceedings.
              </p>
              <br />
              <div className="w-full flex justify-start">
                <h2 className="">
                  <b>Changes to this Policy</b>
                </h2>
              </div>
              <br />
              <p className="text-sm">
                We may update this Policy from time to time. If we make material
                changes to this Policy, we will notify you by email or by
                posting a notice on our Site prior to the effective date of the
                changes.
              </p>
              <br />
              <div className="w-full flex justify-start">
                <h2>
                  <b>Contact Us</b>
                </h2>
              </div>
              <br />
              <p className="text-sm">
                If you have any questions or concerns about this Policy, please
                contact us at{" "}
                <a href="mailto:terms@mettaprotocol.io.">
                  {" "}
                  <b>terms@mettaprotocol.io.</b>
                </a>
              </p>
            </div>
            <div className="flex justify-center items-center gap-5">
              <button className="btn_accept" onClick={handleAccept}>
                Accept
              </button>
              <button className="btn_decline" onClick={handleDecline}>
                Decline
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TermsAndConditionCheck;
