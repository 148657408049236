import React, { useEffect, useState } from "react";
import Header from "../header/header";
import NavBar from "../header/NavBar";
import "./staking.css";
import logo1 from "../../assets/Metta Browser.png";
import { FaAngleRight } from "react-icons/fa";
import Brands from "./Brands";
import Main from "./Main";
import AlertBox from "../alertBox/AlertBox";

import { useBlockChainData } from "../../hooks/loadBlockchainData";
import Subscribe from "./Subscribe";
import Footer from "../footer/footer";
import NFTstaking from "../nftStaking/NFTstaking";

const Staking = () => {
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  useBlockChainData(setDataLoading);
  return (
    <div>
      <Header />
      <div className="stakingMain">
        <NavBar />
        <div className="main_staking">
          <div className="staking">
            <div className="staking_left">
              <div className="inner_left">
                <span>
                  <img src={logo1} alt="logo" />
                </span>
                <h1>
                  <span style={{ color: "#D12942" }}>METTA</span> STAKING LAB
                </h1>
              </div>
              <p>
                Stake METTA and earn a share of the tokens purchased via auto-
                buyback. Safely secured within Metta Protocol’s on-chain NFT
                Stakes that are tradable, bridgeable, and transferable across 5
                blockchains.
              </p>
              <div className="btn_container">
                <button className="btn_ctn_1">
                  $METTA
                  <FaAngleRight
                    style={{
                      marginLeft: "1rem",
                      height: "1.5rem",
                      width: "1rem",
                    }}
                  />
                </button>
                <button className="btn_ctn_2">
                  Launch DAPP
                  <FaAngleRight
                    style={{
                      marginLeft: "1rem",
                      height: "1.5rem",
                      width: "1rem",
                    }}
                  />
                </button>
              </div>
            </div>
            <div className="staking_right">
              <div className="">
                <div className="staking_right_top">
                  <img src={logo1} alt="" />
                </div>
              </div>
              <div className="staking_heading">
                <h1>Stake your METTA tokens now</h1>
              </div>
              <div>
                <p>
                  Stake and earn a share of the tokens purchased via auto-
                  buyback.
                </p>
              </div>
              <div>
                <button className="btn_staking_main">
                  Stake MEtta now{" "}
                  <span>
                    <FaAngleRight
                      style={{
                        marginLeft: "1rem",
                        height: "1.5rem",
                        width: "1rem",
                      }}
                    />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Brands />
      </div>
      <Main setError={setError} setErrMsg={setErrMsg} />
      <div className="h-[2px] w-[100%] bg-[#D43143]" />
     
      <NFTstaking />
      <Subscribe />
      <Footer />
    </div>
  );
};

export default Staking;
