import React from "react";
import "./home.css";
import image1 from "../../assets/Metta Browser.png";

import { FaAngleRight } from "react-icons/fa";
const CardThree = () => {
  const data = [
    {
      id: 1,
      image: image1,
      title: "Metta Motors",
      description:
        "Metta Motors is the world’s first crypto and NFT powered car rental company. We offer a unique system that allows travelers to rent cars and recoup their costs by selling their NFT receipts on the market, or by holding or staking them for tokens.",
        link: "https://mettamotors.com/"
    },
    {
      id: 2,
      image: image1,
      title: "Metta Hotels",
      description:
        "Metta Motors is the world’s first crypto and NFT powered Hotel rental company. We offer a unique system that allows travelers to rent a hotel room and recoup their costs by selling their NFT receipts on the market, or by holding or staking them for tokens.",
        link: "https://mettarooms.com/"
    },
    {
      id: 3,
      image: image1,
      title: "Metta Properties",
      description:
        "Metta Properties is the world’s first all-crypto real estate broker company, allowing you to purchase any property in any country without having to cash out your cryptocurrencies or suffer from high fees. Buy Metta Token if you haven't done so.",
        link: "https://mettaproperties.com/"
    },
  ];
  return (
    <>
      <div className="contianer_card_three">
        {data.map((item) => {
          return (
            <div className="cardThreeContainer">
              <div className="imagCardContainer">
                <img src={item.image} alt="" />
              </div>
              <h1>{item.title}</h1>
              <p>{item.description}</p>
              <a href={item.link}>
              <button className="cardOneBtnBTN flex justify-center items-center">
                Launch DAPP
                <span className="span_2">
                  <FaAngleRight
                    style={{
                      marginLeft: "1rem",
                      height: "1.5rem",
                      width: "1rem",
                    }}
                  />
                </span>
              </button>
              </a>
              <div className="learnMore m-1">
                <h1>Learn More</h1>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CardThree;
