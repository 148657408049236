import React from "react";
import "./subscribe.css";

const Subscribe = () => {
  return (
    <div className="bg-[#0a1f50] p-[1rem] md:p-[4rem] lg:p-[5rem] flex flex-col gap-8 items-center justify-center">
      <div>
        <h1 className="headSubscribe">Be the first to know!</h1>
      </div>
      <div>
        <p className="text-center text-white">
          Subscribe to the METTA Protocol newsletter and never miss <br />{" "}
          important updates and anouncements!
        </p>
      </div>
      <div className="subscribeContainer bg-white flex  h-[70px] w-[780px] mr-40">
        <input
          type="text"
          className="input-1 text-[#c4cbd4] "
          placeholder="First Name"
        />
        <div className="subscribeDiv"></div>
        <input
          type="text"
          className="input-2 text-[#c4cbd4] "
          placeholder="Email"
        />
        <button
          className="subscribeBTN"
          style={{
            background: "rgb(247,130,69)",
            background:
              "linear-gradient(90deg, rgba(247,130,69,1) 29%, rgba(208,38,66,1) 100%)",
            color: "white",
          }}
        >
          SUBSCRIBE
        </button>
      </div>
      <div className="subscribeContainer-hidden">
        <input
          type="text"
          className="input-1-hidden text-[#c4cbd4] ml-1"
          placeholder="First Name"
        />
        <div className="subscribeDiv-hidden"></div>
        <input
          type="text"
          className="input-2-hidden text-[#c4cbd4] "
          placeholder="Email"
        />
        <button
          className="subscribeBTN-hidden"
          style={{
            background: "rgb(247,130,69)",
            background:
              "linear-gradient(90deg, rgba(247,130,69,1) 29%, rgba(208,38,66,1) 100%)",
          }}
        >
          SUBSCRIBE
        </button>
      </div>
    </div>
  );
};

export default Subscribe;
