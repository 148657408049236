import React from "react";
import "./navy.css";
import Cointelegraph from "../../assets/Cointelegrap.png";
import international from "../../assets/internationalBusinessLogo.png";
import yahoo from "../../assets/yahoo.png";
import benzinga from "../../assets/benzinga.png";
import bitcoin from "../../assets/bitcoin.png";
import { FaAngleRight } from "react-icons/fa";
const Brands = () => {
  return (
    <>
      <div className="bg-navy w-[100%]">
        <div className="navContainer flex justify-center items-center gap-10 flex-wrap pt-[1rem] pb-[1rem]">
          <div className="h-[70px] w-[190px]">
            <img
              src={Cointelegraph}
              alt="Cointelegraph"
              className="h-[100%] w-[100%]"
            />
          </div>
          <div className="h-[70px] w-[180px] ">
            <img
              src={international}
              alt="Cointelegraph"
              className="h-[100%] w-[100%] "
            />
          </div>
          <div className="h-[50px] w-[150px] ">
            <img
              src={yahoo}
              alt="Cointelegraph"
              className="h-[100%] w-[100%] "
            />
          </div>
          <div className="h-[70px] w-[180px] ">
            <img
              src={benzinga}
              alt="Cointelegraph"
              className="h-[100%] w-[100%] "
            />
          </div>
          <div className="h-[70px] w-[180px] ">
            <img
              src={bitcoin}
              alt="Cointelegraph"
              className="h-[100%] w-[100%] "
            />
          </div>
          <button className="btnNavy rounded-2xl bg-[#0f5a97] text-[white] flex justify-center items-center h-[40px] pl-4 pr-4 ">
            ALL PRESS
            <FaAngleRight className=" pr-2 pl-3" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Brands;
