import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CONFIG from "./abi/config.json";
import abi from "./abi/abi.json";
import { useEffect, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";

// providerOptions
const providerOptions = {
  network: "mainnet",
  cacheProvider: false,
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: {
        97: process.env.REACT_APP_BSC_RPC,
      },
    },
  },
};

// -----------------------handleWalletConnect-----------------------//
export const handleWalletConnect = async (
  updateWeb3Provider,
  updateAccount,
  updateError,
  updateErrMsg,
  fetchAccountData
) => {
  const web3modal = new Web3Modal({
    providerOptions,
  });

  const instance = await web3modal.connect();
  console.log(instance);
  const provider = new ethers.providers.Web3Provider(instance);
  console.log(provider);
  // console.log(updateWeb3Provider);
  updateWeb3Provider(provider);
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  updateAccount(address);
  const network = await provider.getNetwork();
  console.log(network);
  if (network.chainId !== CONFIG.NETWORK_ID) {
    updateError(true);
    updateErrMsg(
      `Contract is not deployed on current network. please choose ${CONFIG.NETWORK_NAME}`
    );
  } else {
    updateError(false);
    updateErrMsg("");
    fetchAccountData(provider);
    // loadAccountData(signer, address)
  }
};

// -----------------------updateAcc-----------------------//

export const disconnectWallet = (
  updateAccount,
  updateStakedBalance,
  updateRewardBalance,
  updateTokenBalance,
  updateWeb3Provider
) => {
  updateAccount(null);
  updateStakedBalance({
    plan0: null,
    plan1: null,
    plan2: null,
    plan3: null,
    plan4: null,
  });
  updateRewardBalance({
    plan0: null,
    plan1: null,
    plan2: null,
    plan3: null,
    plan4: null,
  });
  updateTokenBalance(null);
  updateWeb3Provider(null);
};

// -----------------------addToken-----------------------//

export const addToken = async () => {
  const tokenAddress = CONFIG.TOKEN_CONTRACT;
  const tokenSymbol = CONFIG.TOKEN_SYMBOL;
  const tokenDecimals = CONFIG.TOKEN_DECIMAL;
  const tokenImage = "";

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.log(error);
  }
};
