import React, { useContext, useEffect, useState, useRef } from "react";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Header from "../header/header";
import Footer from "../footer/footer";
import NavBar from "../header/NavBar";
import collage from "./images/collage.png";
import logo from "./images/logo.png";
import "./config/theme.css";
import { GlobalContext } from "../../context/GlobalContext";
import { ethers } from "ethers";
import { fetchMintingData } from "../../context/GlobalContext";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CONFIG from "./config/config.json";
import mingtingABI from "./config/abi.json";

const providerOptions = {
  cacheProvider: false,
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: {
        56: process.env.REACT_APP_BSC_RPC,
      },
    },
  },
};

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;
export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledLink2 = styled.a`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  text-decoration: none;
  text-align: center;
  font-weight: normal;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 200px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

const Minting = () => {
  const {
    account,
    error,
    errMsg,
    mintingData,
    web3Provider,
    updateError,
    updateErrMsg,
    updateAccount,
    updateStakedBalance,
    updateRewardBalance,
    updateTokenBalance,
    updateWeb3Provider,
    fetchAccountData,
    fetchMintingDataRequest,
    fetchMintingDataSuccess,
    fetchMintingDataFailed,
    fetchMintingData,
  } = useContext(GlobalContext);
  const [mintAmount, setMintAmount] = useState(1);
  const { cost, maxSupply, totalSupply } = mintingData;
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  const handleWalletConnect = async () => {
    const web3modal = new Web3Modal({
      providerOptions,
    });

    const instance = await web3modal.connect();
    console.log(instance);
    const provider = new ethers.providers.Web3Provider(instance);
    console.log(provider);
    // console.log(updateWeb3Provider);
    updateWeb3Provider(provider);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    updateAccount(address);
    const network = await provider.getNetwork();
    console.log(network);
    if (network.chainId !== CONFIG.NETWORK.ID) {
      updateError(true);
      updateErrMsg(
        `Contract is not deployed on current network. please choose ${CONFIG.NETWORK.NAME}`
      );
    } else {
      updateError(false);
      updateErrMsg("");
      fetchAccountData(provider);
      fetchMintingData(provider);
      // loadAccountData(signer, address)
    }
  };
  const getData = async () => {
    if (account !== "") {
      fetchMintingData(web3Provider);
    }
  };
  const claimNFTs = async () => {
    let cost = mintingData.cost;
    let GAS_LIMIT = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(GAS_LIMIT * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    const signer = web3Provider.getSigner();
    const address = await signer.getAddress();
    try {
      const contract = new ethers.Contract(
        CONFIG.CONTRACT_ADDRESS,
        mingtingABI,
        signer
      );
      const estimate = await contract.estimateGas.mint(address, mintAmount);
      console.log(estimate.toString());
      const tx = await contract.mint(address, mintAmount, {
        gasLimit: estimate.toString(),
      });
      await tx.wait();
      console.log(tx);
      setFeedback(
        `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
      );
      setClaimingNft(false);
      fetchMintingData(account);
    } catch (e) {
      console.log(e);
      setFeedback("Sorry, something went wrong please try again later.");
      setClaimingNft(false);
    }
  };

  const getConfig = async () => {
    try {
      const configResponse = await fetch("./config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const config = await configResponse.json();
      SET_CONFIG(config);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getConfig();
    // getData();
  }, []);
  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 50) {
      newMintAmount = 50;
    }
    setMintAmount(newMintAmount);
  };

  return (
    <s.Screen>
      <Header />
      <NavBar />
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? collage : null}
      >
        <div style={{ maxWidth: "280px", zIndex: 1 }}>
          <img
            className="boxShadow"
            src={logo}
            style={{ width: "100%", borderRadius: "10px", background: "white" }}
          />
        </div>
        <s.SpacerLarge />
        <ResponsiveWrapper style={{ padding: 24, zIndex: 1 }}>
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
              maxWidth: "600px",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
                fontFamily: "var(--text)",
              }}
            >
              {totalSupply} / {maxSupply !== 0 ? maxSupply : CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
                fontFamily: "var(--text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", fontFamily: "var(--text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", fontFamily: "var(--text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", fontFamily: "var(--text)" }}
                >
                  1 {CONFIG.SYMBOL} costs{" "}
                  {cost !== 0
                    ? ethers.utils.formatUnits(cost)
                    : CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", fontFamily: "var(--text)" }}
                >
                  Excluding gas fees.
                </s.TextDescription>
                <s.SpacerSmall />
                {account === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        fontFamily: "var(--text)",
                      }}
                    >
                      Connect to the
                      <span style={{ textTransform: "uppercase" }}>
                        {" "}
                        {CONFIG.NETWORK.NAME}
                      </span>{" "}
                      network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton onClick={handleWalletConnect}>
                      CONNECT
                    </StyledButton>
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        fontFamily: "var(--text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <div className="styleBtnContainer">
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                      <StyledLink2
                        target={"_blank"}
                        href={CONFIG.MARKETPLACE_LINK + account}
                      >
                        Opensea
                      </StyledLink2>
                    </div>
                  </>
                )}
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>

        <s.SpacerMedium />
        <s.Container
          jc={"center"}
          ai={"center"}
          style={{ width: "70%", textAlign: "center" }}
        >
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
              fontFamily: "var(--text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME}) and the correct address. Please note: Once
            you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          {/* <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription> */}
        </s.Container>
      </s.Container>
      <Footer />
    </s.Screen>
  );
};

export default Minting;

// testnet 0x8cc4E8291011DDAEa3852eda542eeBE12228A799

// mainnet 0x92D7B31fd396F4981eF174CeD6d7526E049a9C49
