import nft3 from "../../assets/nft3.png";
import nft4 from "../../assets/nft4.png";
import nft5 from "../../assets/nft5.jpg";

export const stakingData = [
  {
    id: "1",
    image: nft3,
    name: "NFT 1",
  },
  {
    id: "2",
    image: nft4,
    name: "NFT 2",
  },
  {
    id: "3",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "4",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "5",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "40",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "600",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "6",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "400",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "30",
    image: nft5,
    name: "NFT 3",
  },
  {
    id: "20",
    image: nft5,
    name: "NFT 3",
  },
];
