import React from "react";
import "./NFTmain.css";
import "animate.css";
import { FaAngleRight } from "react-icons/fa";

import Mettarectangle from "../../assets/mettamotorslogo.png";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CONFIG from "../../abi/config";
import { useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { GlobalContext } from "../../context/GlobalContext";
import { stakingData } from "./stakingData";
import StakeTimerComponent from "./startDate";
import useFetchNfts from "../../hooks/useFetchNfts";
import NFTList from "./NFTList";

const providerOptions = {
  network: "mainnet",
  cacheProvider: false,
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: {
        97: process.env.REACT_APP_BSC_RPC,
      },
    },
  },
};

const NFTMain = ({ setError, setErrMsg }) => {
  
  const {
   account,
    error,
    errMsg,
    web3Provider,
    updateError,
    updateErrMsg,
    updateAccount,
    updateStakedBalance,
    updateRewardBalance,
    updateTokenBalance,
    updateWeb3Provider,
    fetchAccountData,
  } = useContext(GlobalContext);

  console.log(stakingData);
  const [fetchNFTs, setFetchNfts] = useState(true)
  const [fetchStakedNFTs, setFetchStakedNFTs] = useState(true)
  
  const handleWalletConnect = async () => {
    const web3modal = new Web3Modal({
      providerOptions,
    });
    
    const instance = await web3modal.connect();
    console.log(instance);
    const provider = new ethers.providers.Web3Provider(instance);
    console.log(provider);
    // console.log(updateWeb3Provider);
    updateWeb3Provider(provider);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    updateAccount(address);
    const network = await provider.getNetwork();
    console.log(network);
    if (network.chainId !== CONFIG.chainId) {
      updateError(true);
      updateErrMsg(
        `Contract is not deployed on current network. please choose ${CONFIG.networkName}`
        );
      } else {
        updateError(false);
        updateErrMsg("");
        fetchAccountData(provider);
        // loadAccountData(signer, address)
      }
    };

  const disconnectWallet = () => {
    updateAccount(null);
    updateStakedBalance({
      plan0: null,
      plan1: null,
      plan2: null,
      plan3: null,
      plan4: null,
    });
    updateRewardBalance({
      plan0: null,
      plan1: null,
      plan2: null,
      plan3: null,
      plan4: null,
    });
    updateTokenBalance(null);
    updateWeb3Provider(null);
  };

  useEffect(() => {
    if (window.ethereum) {
      setFetchNfts(true)
      setFetchStakedNFTs(true)
      window.ethereum.on("accountsChanged", (accounts) => {
        updateAccount(accounts[0]);
        fetchAccountData(web3Provider);
      });
      window.ethereum.on("chainChanged", (chainId) => {
        window.location.reload();
      });
    }
  }, [account]);
  const [stake, setStake] = useState(false);
  const handleClick = () => {
    setStake(true);
    setMyNfts(false);
  };
  const [myNfts, setMyNfts] = useState(true);
  const handleMyNfts = () => {
    setMyNfts(true);
    setStake(false);
  };

  const [tenureOne, setTenureOne] = useState(false);
  const [tenureTwo, setTenureTwo] = useState(false);
  const [tenureThree, setTenureThree] = useState(false);
  const [tenureFour, setTenureFour] = useState(false);

  const setPlanThirty = () => {
    setTenureOne(!tenureOne);
    setTenureTwo(false);
    setTenureThree(false);
    setTenureFour(false);
  };
  const setPlanSixty = () => {
    setTenureOne(false);
    setTenureTwo(!tenureTwo);
    setTenureThree(false);
    setTenureFour(false);
  };
  const setPlanNinety = () => {
    setTenureOne(false);
    setTenureTwo(false);
    setTenureThree(!tenureThree);
    setTenureFour(false);
  };
  const setPlanOneTwenty = () => {
    setTenureOne(false);
    setTenureTwo(false);
    setTenureThree(false);
    setTenureFour(!tenureFour);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="nftHeading animate__animated animate__fadeInDown ">
        STAKE AND EARN
      </h1>
      <p className="font-bold text-[22px] py-2 px-2 text-center w-[70%] text-white animate__animated animate__fadeInDown ">
        NFT STAKING
      </p>
      <p className="font-bold text-[18px] text-center w-[70%] text-white animate__animated animate__fadeInDown ">
        You can buy ETH directly through Metamask or you can transfer some from
        another wallet or crypto exchange.
      </p>
      <div className="mt-10">
        {account ? (
          <button
            className="buttonMAINNFT py-3 px-3 ml-4  text-[18px] w-[140px] h-[70px]  bg-white text-black truncate uppercase font-medium group transition ease-in-out duration-300 hover:border-[#FE4C2F] hover:bg-[#FE4C2F] hover:text-white flex items-center justify-center space-x-2 rounded-2xl animate__animated animate__fadeInDown"
            onClick={() => disconnectWallet()}
            style={{
              background: "rgb(247,130,69)",
              background:
                "linear-gradient(90deg, rgba(247,130,69,1) 29%, rgba(208,38,66,1) 100%)",
            }}
          >
            <span className=" text-white">
              {account.slice(0, 5) + "..." + account.slice(37, 42)}
            </span>
          </button>
        ) : (
          <button
            className="buttonMAINNFT py-3 px-3 ml-3 text-[18px] w-[140px] h-[70px]   text-white truncate uppercase group transition ease-in-out duration-300 hover:border-[#FE4C2F] hover:bg-[#FE4C2F] hover:text-white flex items-center justify-center space-x-2 rounded-3xl font-bold animate__animated animate__fadeInDown"
            onClick={() => handleWalletConnect()}
            style={{
              background: "rgb(247,130,69)",
              background:
                "linear-gradient(90deg, rgba(247,130,69,1) 29%, rgba(208,38,66,1) 100%)",
            }}
          >
            <span className="">Connect</span>
          </button>
        )}
      </div>
      <div>
        {account ? (
          <NFTList account={account} setFetchNfts = {setFetchNfts} fetchNFTs = {fetchNFTs} fetchStakedNFTs= {fetchStakedNFTs} setFetchStakedNFTs={setFetchStakedNFTs} />
        ) : null}
      </div>
    </div>
  );
};

export default NFTMain;

//fixes