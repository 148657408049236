// const CONFIG = {
//   chainId: 97,
//   // contractAddress: "0xa6CbC98Bb8AaAb6A54Ff2EF915152e4ba6d8D551",
//   contractAddress: "0x4Ec40aA52129e084fB9dCFBba53Ff49bd8D9323A",
//   tokenAddress: "0x8FDB8fb699653592dD19f3Ba2BeCF819F2eCaA2c",
//   tokenDecimals: "9",
//   tokenSymbol: "METTA",
//   networkName: "BSC Testnet",
//   UNISWAP_ROUTER_ADDRESS: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1", 
//   NFT_CONTRACT_ADDRESS: "0x0e571BeAc729BA836eCB1376C0F59b2A7155233E", 
//   NFT_STAKING_CONTRACT: "0xF277EF024c2562D3CD5edDC027a29928D842dB05"
// };

const CONFIG = {
  chainId: 56,
  contractAddress: "0xc9f3DAC31901607a55556c6519cca4b623EAaf8a",
  tokenAddress: "0xf5135EA29aD9705E9FFFECdBe27BFBd30a60C323",
  tokenDecimals: "9",
  tokenSymbol: "METTA",
  networkName: "BSC Mainnet",
  UNISWAP_ROUTER_ADDRESS: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",  // testnet
  NFT_CONTRACT_ADDRESS: "0x92D7B31fd396F4981eF174CeD6d7526E049a9C49", 
  NFT_STAKING_CONTRACT: "0xc0ae6b184D917574dd5B98f64A6000FCe261768A"
};

export default CONFIG;
