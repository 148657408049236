import React from "react";
import Header from "../header/header";
import NavBar from "../header/NavBar";
import "./service.css";
import { FaAngleRight } from "react-icons/fa";
import Footer from "../footer/footer";

const Services = () => {
  return (
    <>
      <div>
        <Header />
        <NavBar />
        <div className="flex justify-center items-center">
          <div className="flex flex-col justify-center items-center mt-10">
            <h1 className="text-[22px] font-extrabold text-[#B79D03]">
              Services
            </h1>
            <div className="flex flex-col justify-center items-center w-[95%] lg:w-[800px] bg-[#001841] p-[2rem]  rounded-lg mt-10">
              <br />
              <ul className="ml-3 list-decimal text-[14px] lg:text-[16px] font-semibold text-white leading-relaxed flex flex-wrap justify-center">
                <li className="box_li">
                  NFT Minting Engine
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
                <li className="box_li">
                  NFT Marketplace
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
                <li className="box_li">
                  NTF Artwork
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
                <li className="box_li">
                  NFT Staking
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
                <li className="box_li">
                  Launchpad/ ICO / IDO
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
                <li className="box_li">
                  Crypto Staking
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
                <li className="box_li">
                  Gaming
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
                <li className="box_li">
                  MLM
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
                <li className="box_li">
                  Swapping/ Staking
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
                <li className="box_li">
                  Bridging
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
                <li className="box_li">
                  Website (JavaScript, Web3)
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
                <li className="box_li">
                  Website (WordPress)
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
                <li className="box_li">
                  {" "}
                  Audit Services
                  <span>
                    <FaAngleRight />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Services;
