import React, { useState } from "react";
import logo from "../../assets/MettaProtocol Logo.png";
import "./header.css";
import { FaAngleRight } from "react-icons/fa";
import { GoTriangleDown } from "react-icons/go";
import MobileHeader from "./MobileHeader";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { useContext } from "react";
import { useEffect } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CONFIG from "../../abi/config";

const NavBar = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  return (
    <>
      <div className="navbar">
        <div
          className="headerLogo cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img src={logo} alt="" />
          <span id="menuBarPriceLbl">$0.01</span>
        </div>
        <div>
          <MobileHeader />
          <ul className="nav-links">
            <li>
              <button className="btnbtn" onClick={() => setShow(!show)}>
                LAUNCH DAPP
                <FaAngleRight
                  style={{
                    marginLeft: "4px",
                    width: "1.2rem",
                    height: "1.2rem",
                  }}
                />
                <GoTriangleDown style={{ marginLeft: "4px" }} />
              </button>
              {show ? (
                <div className="innerLi">
                  <li className="innerli" onClick={() => navigate("/Swapping")}>
                    <span style={{ paddingLeft: "10px" }}>METTASWAP </span>
                  </li>
                  <li className="innerli" onClick={() => navigate("/staking")}>
                    <span style={{ paddingLeft: "10px" }}>METTASTAKE</span>
                  </li>
                  <li className="innerli" onClick={() => navigate("/minting")}>
                    <span style={{ paddingLeft: "10px" }}>NFT MINTING</span>
                  </li>
                  <li className="innerli" onClick={() => navigate("/ico")}>
                    <span style={{ paddingLeft: "10px" }}>METTA ICO</span>
                  </li>
                </div>
              ) : null}
            </li>
            <li className="nav-item">
              <span onClick={() => setShow1(!show1)} className="flex">
                Ecosystem{" "}
                <GoTriangleDown
                  style={{ marginLeft: "4px", marginTop: "2px" }}
                />
              </span>
              {show1 ? (
                <div className="innerLi2">
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.mettamotors.com"
                      target="_blank"
                    >
                      Metta Motors&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.mettabrowser.com"
                      target="_blank"
                    >
                      Metta Browser&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.mettapays.com"
                      target="_blank"
                    >
                      MettaPay&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.mettapass.com"
                      target="_blank"
                    >
                      MettaPass&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.mettaseed.com"
                      target="_blank"
                    >
                      MettaSeed&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.mettatax.com"
                      target="_blank"
                    >
                      MettaTax&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.mettasnap.com"
                      target="_blank"
                    >
                      MettaSnap&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.mettarooms.com"
                      target="_blank"
                    >
                      Metta Hotel&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.mettaproperties.com"
                      target="_blank"
                    >
                      Metta Properties&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.heymetta.ai"
                      target="_blank"
                    >
                      heyMetta AI&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.mettadapp.com"
                      target="_blank"
                    >
                      MettaDApp&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://vcard.heymetta.com"
                      target="_blank"
                    >
                      Metta vCard&trade;
                    </a>
                  </li>
                  <li className="innerli2">
                    <a
                      style={{ paddingLeft: "10px" }}
                      href="https://www.radiomettafm.com"
                      target="_blank"
                    >
                     Radio Metta FM &trade;
                    </a>
                  </li>
                </div>
              ) : null}
            </li>
            <li className="nav-item">
              <a href="https://www.mettatoken.org">Buy Metta</a>
            </li>
            <li className="nav-item">
              <a href="https://www.mettapass.com">Join Community</a>
            </li>
            <li className="nav-item">Partners</li>
            <li className="nav-item" onClick={() => navigate("/dao")}>
              Dao
            </li>
            <li className="nav-item">Defi-camp</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NavBar;
