import { FaAngleDown } from "react-icons/fa";

import { useState } from "react";

const TokenInput = ({
  selToken,
  value,
  setValue,
  disabled,
  openList,
  onBlur,
}) => {
  return (
    <div className="rounded-xl bg-[#002B8A] flex px-2 py-2 mx-6 items-center">
      <div className="flex items-center justify-center">
        <div className="w-10">
          <img src={selToken?.logoURI} alt="" />
        </div>
        <input
          disabled={disabled}
          className="w-full bg-transparent text-[#8899C6] px-2 focus:outline-none"
          value={value}
          type="text"
          placeholder="0"
          onBlur={onBlur}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <button
        className="bg-[#021335] rounded-2xl text-[#8899C6] flex justify-center items-center px-4 py-2 hover:bg-[#071a41] min-w-fit"
        onClick={() => openList(true)}
      >
        {selToken?.symbol}
        <FaAngleDown className="ml-2" />
      </button>
    </div>
  );
};

export default TokenInput;
